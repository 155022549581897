import React, {FunctionComponent, useCallback, useRef, useState, useEffect } from 'react';
import {useEffectOnce, useLocalStorage} from "usehooks-ts";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {
    useGetMultiFactorUrlMutation, 
    useGetUserRoleQuery,
    useMultiFactorVerifyMutation
} from "../../../api/devices";
import {routesPath} from "../../../routes/const";
import {useNavigate, useSearchParams} from "react-router-dom";
import MyDevicesVerification from "./MyDevicesVerification";

interface OwnProps {
    openSidePanel: boolean,
    // onVerify: (value: boolean) => void,
    handleOpenSidePanel: () => void,
    handleCloseSidePanel: () => void
}

type Props = OwnProps;

const VerificationSigma: FunctionComponent<Props> = ({
    // onVerify, 
    openSidePanel, 
    handleCloseSidePanel, 
    handleOpenSidePanel
}) => {

  const ErrorMultifactorServer = "exc=602";
  const alertEsiaAutoOpen = useRef<ReturnType<typeof setTimeout>>();


  const navigate = useNavigate();
  const [searchParams] = useSearchParams();


  // --- запросы

  const [getMultiFactorUrl, {error:errorGetMultiFactorUrl}] = useGetMultiFactorUrlMutation()


  const [multiFactorVerify, {
      data: multiFactorVerifyData,
      error: multiFactorVerifyError,
      reset: multiFactorVerifyReset
  }] = useMultiFactorVerifyMutation({fixedCacheKey: 'shared-mutifactor'});


  const {data: userRole} = useGetUserRoleQuery(undefined);

  // ---

  const [, setEsiaDevicesOmegaReferrer] = useLocalStorage('esiaDevicesOmegaReferrer', false);
  const [, setSberIdDevicesOmegaReferrer] = useLocalStorage('sberIdDevicesOmegaReferrer', false);

  const [isEsiaDevicesSigmaReferrer, setEsiaDevicesSigmaReferrer] = useLocalStorage('esiaDevicesSigmaReferrer', false);
  const [isSberIdDevicesSigmaReferrer, setSberIdDevicesSigmaReferrer] = useLocalStorage('sberIdDevicesSigmaReferrer', false);


  const [multiFactorErrorMessage, setMultiFactorErrorMessage] = useState("");
  const [esiaLoad, setEsiaLoad] = useState(false);
  const [sberIdLoad, setSberIdLoad] = useState(false);
  const [alertEsia, setAlertEsia] = useState(false);

  const multiFactorEnabled = userRole?.result.ui_settings.multifactorEnabled;
  const multifactorErrorText = multiFactorErrorMessage || multiFactorVerifyError?.message || errorGetMultiFactorUrl?.message || "";
  
  const isNewDeviceAddingFlow = Boolean(userRole?.result.available_sections.find(e => e === "web_totp"));

  const onClose = useCallback(() => {
    handleCloseSidePanel();
    multiFactorVerifyReset();
  }, [handleCloseSidePanel, multiFactorVerifyReset])

  useEffect(() => {
    if (!openSidePanel) {
      onClose()
    }
  }, [openSidePanel, onClose]);

  const clearReferrer = () => {
      setSberIdDevicesOmegaReferrer(false)
      setEsiaDevicesOmegaReferrer(false)
      setEsiaDevicesSigmaReferrer(false)
      setSberIdDevicesSigmaReferrer(false)
  }


  const handleClickSberId = async () => {

      // отправка метрик click stream
      sendEvent(
          {
              eventCategory: 'Регистрация токена',
              eventAction: 'клик на кнопку  "Войти по Сбер ID"',
              value: ''
          }
      )
      //
      clearReferrer()
      setSberIdDevicesSigmaReferrer(true)
      setSberIdLoad(true)
      const response = await getMultiFactorUrl('sber-id')
      if ("data" in response) {
          const {url} = response.data.result
          const a = document.createElement('a');
          a.href = url;
          a.click();
      }
      setSberIdLoad(false)
  }

  const handleClickEsia = async () => {

      // отправка метрик click stream
      sendEvent(
          {
              eventCategory: 'Регистрация токена',
              eventAction: 'клик на кнопку  "Войти через Госуслуги"',
              value: ''
          }
      )
      //
      clearReferrer()
      setEsiaDevicesSigmaReferrer(true)
      setEsiaLoad(true)
      const response = await getMultiFactorUrl('esia')
      if ("data" in response) {
          const {url} = response.data.result;
          const a = document.createElement('a');
          a.href = url;
          a.click();
      }
      setEsiaLoad(false)
  }

  const onMount = async () => {
      if (searchParams.toString() && (isEsiaDevicesSigmaReferrer || isSberIdDevicesSigmaReferrer) && multiFactorEnabled && isNewDeviceAddingFlow) {
          if (searchParams.toString() === ErrorMultifactorServer) {
              handleOpenSidePanel();
              setMultiFactorErrorMessage("Сервер временно недоступен, попробуйте позднее");
              navigate(routesPath.devices);
          } else {
              if (isEsiaDevicesSigmaReferrer) {
                  setEsiaLoad(true)
                  alertEsiaAutoOpen.current = setTimeout(() => {
                      setAlertEsia(true)
                  }, 35000)
              }
              if (isSberIdDevicesSigmaReferrer) {
                  setSberIdLoad(true)
              }
              // Открываем сайдпанель
              handleOpenSidePanel();

              // читаем квери парметры и делаем мультифакторную проверку
              const params = Object.fromEntries(searchParams.entries());
              navigate(routesPath.devices)
              await multiFactorVerify(params)
            //   .then((res) => {
            //     if ("data" in res) {
            //         onVerify(res.data.result?.verified)
            //     }
            //   })
              clearTimeout(alertEsiaAutoOpen.current);
              setAlertEsia(false)
              setEsiaLoad(false)
              setSberIdLoad(false)
          }
          clearReferrer()
      }

  }


  useEffectOnce(() => {
      onMount()
  })

    return (
        <>
          <MyDevicesVerification 
            setAlertEsia={setAlertEsia}
            alertEsia={alertEsia}
            sberIdLoad={sberIdLoad}
            esiaLoad={esiaLoad}
            verified={Boolean(multiFactorVerifyData?.result?.verified)}
            multiFactorEnabled={multiFactorEnabled}
            multifactorErrorText={multifactorErrorText}
            onClickSberId={handleClickSberId}
            onClickEsia={handleClickEsia}
          />
        </>
    );
};

export default VerificationSigma;
