import React, {FunctionComponent, useState} from 'react';
import style from "./MainPage.module.scss";
import {Accordion, Avatar, Button, CircularLoader, Typography, WidgetCard} from "@sber-friend/flamingo-core";
import {ReactComponent as ListIcon} from "../../assets/img/icons/list.svg";
import {ReactComponent as KeyIcon} from "../../assets/img/icons/key.svg";
import {ReactComponent as ExternalLinkIcon} from "../../assets/img/icons/external-link.svg";
import {ReactComponent as SmartphoneIcon} from "../../assets/img/icons/smartphone.svg";
import {useNavigate} from "react-router-dom";
import {routesPath} from "../../routes/const";
import {useEffectOnce, useWindowSize} from "usehooks-ts";
import {getCookie, getPkingUrl, getUserAgent, setCookie} from "../../helpers";
import {useGetSettingsQuery, useLazyGetMobileVersionQuery} from "../../api/main";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {useCountdown} from "../../hooks/common";
import Snackbar from "@sber-friend/flamingo-core/Snackbar";
import QRCode from "react-qr-code";

// import QRCode from "react-qr-code";


interface OwnProps {
}

type Props = OwnProps;

export const MainPage: FunctionComponent<Props> = (props) => {

    const COUNTDOWN_TIMER = 15;

    const [getMobileVersion, {data: mobileVersion}] = useLazyGetMobileVersionQuery();
    const {isLoading: getSettingsLoad, data: getSettingsData} = useGetSettingsQuery(undefined);
    const mpotp_visible = getSettingsData?.result.mpotp_visible;

    const [showDownloadAlert, setShowDownloadAlert] = useState("");

    const {seconds, startCountdown} = useCountdown(0);


    const navigate = useNavigate();

    const userAgent = getUserAgent();

    const {width} = useWindowSize();
    const variantTitle = width > 600 ? "display2" : (width > 375 ? "display3" : "h1");
    const variantSubTitle = width > 600 ? "h4" : "h5";


    const handleDownload = () => {

        if (window) {
            setCookie("startedDownload", Date.now().toString())
            startCountdown(COUNTDOWN_TIMER)


            if (userAgent === "ios") {
                setShowDownloadAlert("Началась установка приложения. Приложение ОТР отобразится на главном экране вашего устройства")
                window.open(window.location.origin + "/store/ios", "_blank")
                // отправка метрик click stream
                sendEvent(
                    {
                        eventCategory: 'Скачать приложение',
                        eventAction: 'Главная страница',
                        value: 'iosDownload'
                    }
                )
                //
            } else {
                setShowDownloadAlert("Началось скачивание приложения. Дистрибутив находится в проводнике файлов")
                window.open(window.location.origin + "/store/android", "_blank")
                // отправка метрик click stream
                sendEvent(
                    {
                        eventCategory: 'Скачать приложение',
                        eventAction: 'Главная страница',
                        value: 'androidDownload'
                    }
                )
                //
            }
        }
    }

    const navigateInstructionsPage = () => {
        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Клик на Инструкции',
                eventAction: 'Главная страница',
                value: ''
            }
        )
        //
        navigate(routesPath.instructions)
    }

    const navigateDevicesPage = () => {
        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Клик на Устройства',
                eventAction: 'Главная страница',
                value: ''
            }
        )
        //
        navigate(routesPath.devices)
    }

    useEffectOnce(() => {
        const startedDownload = getCookie("startedDownload")
        if (Number(startedDownload)) {
            const countdownSecondLeft = COUNTDOWN_TIMER - Math.round((Number(Date.now()) - Number(startedDownload)) / 1000)
            if (countdownSecondLeft > 0) {
                startCountdown(countdownSecondLeft)
            } else {
                setCookie("startedDownload", 0)
            }
        }
        getMobileVersion(undefined)
    })

    return (

        getSettingsLoad ? <div style={{margin: "32px 0", display: "flex", justifyContent: "center"}}>
                <CircularLoader size={"large"}/>
            </div>
            : <div className={style.mainPage}>
                <div className={style.title}>
                    {mpotp_visible && (width > 600) && <QRCode
                        size={256}
                        style={{height: "auto", maxWidth: "110px", width: "100%", marginRight: 32}}
                        value={window?.location?.origin + routesPath.qrCodeDownload}
                        viewBox={`0 0 256 256`}
                    />}
                    <div className={style.text + (" content-pre-line")}>
                        <Typography variant={variantTitle}>OTP – безопасный доступ{'\n'}к продуктам Сбера</Typography>
                        {mpotp_visible && (width > 600) &&
                            <Typography variant={variantSubTitle} color="textSecondary">Отсканируйте QR-код, чтобы
                                установить
                                приложение{'\n'}
                                и получать одноразовые пароли на мобильном устройстве</Typography>}
                    </div>
                    {/*<img src={Illustration} alt="illustration" className={style.illustration}/>*/}
                </div>
                {
                    (userAgent === "android" || (mpotp_visible && userAgent !== "web")) &&
                    <div className={style.mobileVersBlock}>
                        <Typography variant={"body2"} color="textSecondary"
                                    className={style.mobileVersion
                                    }>
                            Версия мобильного приложения OTP: {mobileVersion ? (userAgent === "ios" ?
                                mobileVersion.result.iosVersion
                                : mobileVersion.result.androidVersion)
                            : " -"}
                        </Typography>
                        <Button
                            color={"primary"}
                            onClick={handleDownload}
                            fullWidth={true}
                            disabled={!!seconds}
                        >
                            Скачать {!!seconds && ` (${seconds})`}
                        </Button>
                    </div>
                }
                <div className={style.cardWrap}>
                    <div className={style.card}>
                        <WidgetCard
                            title="Инструкция по установке"
                            avatar={<Avatar letters="list" icon={<ListIcon/>} color={"green"}/>}
                            subtitle="IOS 14+, Android 7+"
                            variant="rectangleFullWidth"
                            inverse
                            onClick={navigateInstructionsPage}
                            bgColor={"#24282B"}
                        />
                    </div>
                    <div className={style.card}>
                        <WidgetCard
                            title="Устройства"
                            avatar={<Avatar letters="list" icon={<SmartphoneIcon/>} color={"green"}/>}
                            subtitle="Просматривайте и добавляйте устройства OTP"
                            variant="rectangleFullWidth"
                            inverse
                            onClick={navigateDevicesPage}
                            bgColor={"#24282B"}
                        />
                    </div>
                    <div className={style.card}>
                        <WidgetCard
                            title="Cертификаты безопасности PKING"
                            avatar={<Avatar letters="list" icon={<KeyIcon/>} color={"green"}/>}
                            subtitle="Новые и старые сертификаты"
                            variant="rectangleFullWidth"
                            inverse
                            onClick={() => {
                            }}
                            bgColor={"#24282B"}
                            badge={<ExternalLinkIcon/>}
                            href={getPkingUrl()}
                        />
                    </div>
                </div>
                <div className={style.questionsWrap + (" content-pre-line")}>
                    <div className={style.head}>
                        <Typography variant={"h4"}>
                            {width > 600 ? "Ответы на частые вопросы" : "Вопросы"}
                        </Typography>
                        <a href="https://sberfriend.sbrf.ru/sberfriend/#/application/97C2E1A8DE5B93ACE054022128574717"
                           className={"green-link"}>
                            Все
                        </a>
                    </div>
                    <Accordion
                        title={"Не принимается ОТР после ввода ?"}
                    >
                        <Typography variant="body1" color="textSecondary">
                            Если Вы вводите пароль ОТР для подключения к VPN и у Вас возникает ошибка Login Failed, то,
                            пожалуйста, проверьте:{'\n'}
                            - актуальность Вашей заявки на удаленный доступ в Друге;{'\n'}
                            - статус Вашей учетной записи(заблокирована/не заблокирована);{'\n'}
                            - если Вы вводили пароль ОТР более 6-ти раз, то Вам следует подождать 10 минут
                        </Typography>
                    </Accordion>
                    <Accordion
                        title={"Что такое VerifyPush ?"}
                    >
                        <Typography variant="body1" color="textSecondary">
                            Технология для беспарольного доступа в АС с помощью мобильного приложение ОТР в разделе
                            "Запросы". Для подключения обратитесь к СУДИР NGAM
                        </Typography>
                    </Accordion>
                    <Accordion
                        className={style.withoutBorder}
                        title={"Что такое ОТР(PasswordLess) ?"}
                    >
                        <Typography variant="body1" color="textSecondary">
                            Решение для быстрой аутентификации пользователя в АС Банка и корпоративных мобильных
                            приложениях. Реализуемые способы аутентификации: без пароля(SSO), временный пароль (OTP),
                            аппаратный токен, TPM, Push
                        </Typography>
                    </Accordion>
                    <Accordion
                        title={"Как подготовить смартфон для установки OTP?"}
                        expanded={false}
                        className={style.hrefAccordion}
                        onClick={() => {
                            navigate(routesPath.instructions)
                        }}
                    >
                        <span></span>
                    </Accordion>
                </div>
                <Snackbar
                    placement={"top"}
                    open={!!showDownloadAlert}
                    autoHideDuration={10000}
                    transitionDuration={300}
                    TransitionProps={{}}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        setShowDownloadAlert("")
                    }}
                    message={showDownloadAlert}
                />
            </div>
    )
};
