import { useState, useEffect, useCallback } from 'react';
import { Alert, Box, Button, Typography, } from '@sber-friend/flamingo-core';
import OTPBox from './OTPBox';
import OTPVerification from "./OTPVerification";
import { useGetOtpMutation, useGetUserRoleQuery, useMultiFactorVerifyMutation } from 'api/devices';
import { getUserAgent } from 'helpers';

const userAgent = getUserAgent();

const OTP = () => {
    const [isPanelOpened, setPanelOpened] = useState(false);
    const [fetchOtp, { data, isLoading, isError }] = useGetOtpMutation();

    const [, {
        data: multiFactorVerifyData,
        // error: multiFactorVerifyError,
        // reset: multiFactorVerifyReset
    }] = useMultiFactorVerifyMutation({ fixedCacheKey: 'shared-mutifactor-otp' });

    const { data: userRole } = useGetUserRoleQuery(undefined);
    const multiFactorEnabled = userRole?.result.ui_settings.multifactorEnabled;

    const isOtpBlockVisible = Boolean(multiFactorVerifyData?.result?.verified) || !multiFactorEnabled;

    const initOtpProcess = useCallback(async () => {
        setIsTimeout(false);
        await fetchOtp();
    }, [fetchOtp]);

    useEffect(()=> {
        if (isOtpBlockVisible && 
            multiFactorEnabled
        ) {
            initOtpProcess();
        }
    }, [isOtpBlockVisible, initOtpProcess, 
        multiFactorEnabled
    ]);

    const { result: {
        end_code_time = 0,
        server_time = 0,
        otp = ''
    } = {} } = data || {};

    const [isTimeout, setIsTimeout] = useState(false);

    const otpSecondsRemain = Math.floor((end_code_time / 1000) - (server_time / 1000));
    const isTimeRemains = otpSecondsRemain > 0 && !isTimeout;

  

    const errorBlock = isError
        ? (
            <Alert severity="error" my={3}>
                Не удалось получить OTP. Нажмите на "Показать OTP", чтобы повторить запрос, или попробуйте позже
            </Alert>
        )
        : null;

    const otpBlock = (
        <>
            <OTPBox
                otp={otp}
                secondsRemain={otpSecondsRemain}
                onTimerOut={() => setIsTimeout(true)}
            />
            <Alert severity="info" my={3}>
                Нажмите на OTP, чтобы скопировать в буфер обмена
            </Alert>
        </>
    );

    const requestBlock = (
        <>
            <Box mb={2.5}>
                <Typography
                    align="center"
                    variant="body1"
                    color="textSecondary"
                >
                    Нажмите на кнопу ниже, чтобы показать OTP
                </Typography>
            </Box>
            <Button
                fullWidth
                loading={isLoading}
                color="primary"
                onClick={() => {
                    if (isOtpBlockVisible) {
                        initOtpProcess();
                        return;
                    }
                    setPanelOpened(true)
                }}
                // onClick={initOtpProcess}
            >
                Показать OTP
            </Button>
        </>
    );

    return (
        <>
            <Box
                m="0 auto"
                pt={userAgent === "web" ? 13.75 : 10}
                maxWidth="398px"
                display="flex"
                justifyContent="center"
                flexDirection="column"
            >
                {
                    isTimeRemains
                        ? otpBlock
                        : requestBlock
                }

                {errorBlock}
            </Box>

            <OTPVerification
                openSidePanel={isPanelOpened}
                handleOpenSidePanel={() => setPanelOpened(true)}
                handleCloseSidePanel={() => setPanelOpened(false)}
            />
        </>
    );
};

export default OTP;
