import React, {useEffect, useState} from 'react';
import {Alert, Button, Checkbox, TextField, Typography} from "@sber-friend/flamingo-core";
import tokenBackImg from "../../../assets/img/Devices/tokenBack.png";
import Snackbar from "@sber-friend/flamingo-core/Snackbar";
import style from "./UnbindingToken.module.scss";
import {useUnbindingTokenMutation, useUnbindingTokenOmegaMutation} from "../../../api/devices";

const UnbindingToken = () => {

    const [unbindingToken, {error, isSuccess, isLoading, reset}] = useUnbindingTokenMutation()
    const [unbindingTokenOmega, {
        error: errorTokenOmega,
        isSuccess: isSuccessTokenOmega,
        isLoading: isLoadingTokenOmega,
        reset: resetTokenOmega
    }] = useUnbindingTokenOmegaMutation()

    const [loginSigma, setLoginSigma] = useState("");
    const [serialNumberField, setSerialNumberField] = useState("");
    const [imageSnackBar, setImageSnackBar] = useState(false);
    const [deleteOmegaToken, setDeleteOmegaToken] = useState(true);

    const conditionUnbindingToken = (!!loginSigma && serialNumberField.length === 10)

    const handleUnbindingToken = () => {
        if (conditionUnbindingToken) {
            if (deleteOmegaToken){
                unbindingTokenOmega({
                    user: loginSigma,
                    serial: serialNumberField,
                })
            } else {
                unbindingToken({
                    user: loginSigma,
                    serial: serialNumberField,
                })
            }
        }
    }

    const clearFields = () => {
        setLoginSigma("")
        setSerialNumberField("")
    }

    useEffect(() => {
        clearFields()
    }, [isSuccess,isSuccessTokenOmega])

    return (
        <div className={style.wrap}>
            <Typography variant={"h5"}>Отвязка токена</Typography>
            <div className={style.fieldWrap}>
                <TextField placeholder={"12345678"}
                           value={loginSigma}
                           onChange={(e) => {
                               if (error) {
                                   reset()
                               }
                               if (errorTokenOmega){
                                   resetTokenOmega()
                               }
                               setLoginSigma(e.target.value)
                           }}
                           label={"Логин Sigma"}
                           onFocusCapture={() => {
                               if (error) {
                                   reset()
                               }
                               if (errorTokenOmega){
                                   resetTokenOmega()
                               }
                           }}
                           onKeyDown={(e) => {
                               if (e.key === "Enter") {
                                   handleUnbindingToken()
                               }
                           }}
                           required
                />
                <TextField placeholder={"1234567890"}
                           value={serialNumberField}
                           onChange={(e) => {
                               if (error) {
                                   reset()
                               }
                               if (errorTokenOmega){
                                   resetTokenOmega()
                               }
                               let newVal = e.target.value.replace(/[^0-9]/g, '')
                               if (newVal.length < 11)
                                   setSerialNumberField(newVal)
                           }}
                           label={"Серийный номер"}
                           required
                           onKeyDown={(e) => {
                               if (e.key === "Enter") {
                                   handleUnbindingToken()
                               }
                           }}
                           onFocusCapture={() => {
                               if (error) {
                                   reset()
                               }
                               if (errorTokenOmega){
                                   resetTokenOmega()
                               }
                           }}
                />
                <Typography variant={"body2"}>
                    <span className="green-link" onClick={() => setImageSnackBar(true)}>Cерийный номер</span>
                    &nbsp;— 10 цифр на обороте токена
                </Typography>
            </div>
            <div style={{marginBottom: 24}}>
                <Checkbox margin={"none"}
                          label={"Отвязать также в сегменте Omega"}
                          checked={deleteOmegaToken}
                          onChange={(event, checked) => setDeleteOmegaToken(checked)}
                />
            </div>
            <Button color={"primary"}
                    disabled={!conditionUnbindingToken}
                    onClick={handleUnbindingToken}
                    loading={isLoading || isLoadingTokenOmega}
            >
                Отвязать
            </Button>
            {error && <div className={style.alertWrap}>
                <Alert severity={"error"}>{error?.message}</Alert>
            </div>}
            {errorTokenOmega && <div className={style.alertWrap}>
                <Alert severity={"error"}>{errorTokenOmega?.message}</Alert>
            </div>}
            <Snackbar
                placement={"top"}
                open={isSuccess || isSuccessTokenOmega}
                autoHideDuration={5000}
                transitionDuration={300}
                TransitionProps={{}}
                onClose={() => {
                    reset()
                    resetTokenOmega()
                }}
                message={"Токен успешно отвязан"}
            />
            <Snackbar
                placement={"bottom"}
                hideIcon
                open={imageSnackBar}
                iconButtonClose
                transitionDuration={300}
                TransitionProps={{}}
                onClose={() => {
                    setImageSnackBar(false)
                }}
                message={<img src={tokenBackImg} alt="tokenBackImg"/>}
            />
        </div>
    );
};

export default UnbindingToken;
