import {createApi,} from "@reduxjs/toolkit/dist/query/react";
import {baseQuery, SuccessResponse} from "../common";

export const GET_MOBILE_APP_VERSION = "/api/portal/mobile/bundles/store_version.json";
export const CHECK_SEGMENT = "/api/portal/check_segment.json";
export const GET_SETTINGS = "/api/portal/settings";

type GetMobileVersionType = {
    androidVersion: string
    iosVersion: string

}

interface ICheckSegment {
    sigma: boolean
}

interface IGetSettings {
    cyberpass_instruction_visible:boolean
    password_visible: boolean
    mpotp_visible: boolean
}





export const mainPageApi = createApi({
    reducerPath: 'mainPageApi',
    baseQuery: baseQuery({
        baseUrl: "/",
    }),
    endpoints: (builder) => ({
        getMobileVersion: builder.query<SuccessResponse<GetMobileVersionType>, undefined>({
            query: () => GET_MOBILE_APP_VERSION,
        }),
        getSegment: builder.mutation<SuccessResponse<ICheckSegment>, undefined>({
            query: () => ({
                url: CHECK_SEGMENT,
                method: "POST",
            }),
        }),
        getSettings: builder.query<SuccessResponse<IGetSettings>, undefined>({
            query: () => GET_SETTINGS,
        }),
    }),
})

export const {useLazyGetMobileVersionQuery, useGetSegmentMutation, useGetSettingsQuery} = mainPageApi;
