interface IOptionsCookie {
    domain?: undefined | string
    expires?: Date |string
    httpOnly?: undefined | false | true
    maxAge?: undefined | number
    path?: undefined | string
    sameSite?: boolean | "lax" | "strict" | "none"
    secure?: undefined | false | true
}

type UserAgent = "web" | "ios" | "android";

function composeDigits(l: number, v: number) {
    return l === 0 ? "00" : l === 1 ? `0${v}` : v;
}

export function formatDateTime(date_time: Date | number | string) {
    if (typeof date_time === "number") {
        const date = new Date(date_time);
        const d = date.toLocaleDateString("ru");
        const h = date.getHours();
        const m = date.getMinutes();
        const h_l = (Math.log(h) * Math.LOG10E + 1) | 0;
        const m_l = (Math.log(m) * Math.LOG10E + 1) | 0;
        const hours = composeDigits(h_l, h);
        const minutes = composeDigits(m_l, m);
        return `${d} в ${hours}:${minutes}`;
    }
    return "Не указан";
}

export function getCookie(name:string) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([$?*|{}\]\\^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
}


export function setCookie(name:string, value:any, options:IOptionsCookie = {} ) {

    options = {
        path: '/',
        // при необходимости добавьте другие значения по умолчанию
        ...options
    };

    if (options?.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey as keyof typeof options];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
}

export function getUserAgent(): UserAgent {
    const agent = window.navigator.userAgent;
    if (agent.match(/Android|Linux/i) && !agent.match(/x86_64/i)) {
        return "android";
    }
    if (agent.match(/iPhone|iPad/i)) {
        return "ios";
    }
    const screenWidth = window?.screen?.width
    if (agent.match(/iPhone|iPad|Mac/i) && (screenWidth < 1367)){
        return "ios";
    }
    return "web";
}

export function getBrowserInfo() {
    const agent = window.navigator.userAgent;

    const screenWidth = window?.screen?.width
    if (agent.match(/iPhone|iPad|Mac/i) && (screenWidth < 1367)){
        // Chrome на iOS (использует WebKit, но с уникальным идентификатором)
        if (/CriOS/.test(agent)) {
            return "Chrome";
        }

        // Firefox на iOS
         if (/FxiOS/.test(agent)) {
             return "Firefox";
        }

        // Opera на iOS (также использует WebKit, но с уникальной строкой в userAgent)
         if (/OPiOS/.test(agent)) {
            return "Opera";
        }

        // Microsoft Edge на iOS (использует WebKit, но имеет идентификатор)
         if (/EdgiOS/.test(agent)) {
             return "Edge";
        }

         // Yandex
        if (/YaBrowser/.test(agent)) {
            return "Yandex"
        }
    }

    if (agent.indexOf("Chrome") > -1){
        return "Chrome"
    }
    if (agent.indexOf("Firefox") > -1){
        return "Firefox"
    }
    if (agent.indexOf("Safari") > -1){
        return "Safari"
    }
    if (agent.indexOf("Edge") > -1){
        return "Edge"
    }
    if (agent.indexOf("MSIE") > -1){
        return "Internet Explorer"
    }
    return "Unknown Browser"
}


export function getStand() {
    const portal = "otp.sberbank.ru";
    const { hostname } = window.location;

    if (hostname === portal) {
        return "prod";
    }
    if (/dev/.test(hostname)) {
        return "dev";
    }
    if (/load/.test(hostname)) {
        return "load";
    }
    if (/release/.test(hostname)) {
        return "release";
    }
    if (/majorcheck|minorcheck/.test(hostname)) {
        return "majorcheck";
    }
    if (/majorgo/.test(hostname)) {
        return "majorgo";
    }
    return "majorcheck";
}

export function getUrlForClickStream():string {
    const stand = getStand();
    if (stand === "prod"){
        return "https://int.clickstream.sberbank.ru/metrics/inner-partners/otp"
        //return "https://clickstream.online.sberbank.ru/metrics/inner-partners/otp"
    }
    if (stand === "majorgo"){
        return "https://psiclickstream.testonline.sberbank.ru:8097/metrics/inner-partners/otp"
    }
    return "https://iftmpclickstream.testonline.sberbank.ru:8097/metrics/inner-partners/otp"
}

export function getPkingUrl():string {
    const stand = getStand();
    if (stand === "prod"){
        return "https://pking.sberbank.ru"
    }
    if (stand === "majorgo"){
        return "https://psi.pking.sigma.sbrf.ru"
    }
    return "https://ift.pking.sigma.sbrf.ru"
}

export async function copyToClipboard(text: string) {
    try {
        await navigator.clipboard.writeText(text);
    } catch (_) {
        throw new Error("Не удалось скопировать в буфер обмена");
    }
}
