import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button, IconButton,
    TextField,
    Typography,
} from "@sber-friend/flamingo-core";
import style from "./MyDevices.module.scss";
import {
    useAddDeviceMutation,
    useIsDeviceAcceptedMutation,
    INewDevice,
    useSendPortalMessageMutation, useGetUserRoleQuery,
} from "../../../api/devices";
import {getCookie, getUserAgent} from "../../../helpers";
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import {useGetSegmentMutation} from "../../../api/main";
import Snackbar from "@sber-friend/flamingo-core/Snackbar";
import {
    CloseIcon
} from '@sber-friend/flamingo-icons';
import {useLocalStorage} from "usehooks-ts";
import {TIosAppChoice} from "./MyDevicesSidePanel";

require('moment-precise-range-plugin');

declare module "moment" {
    interface PreciseRangeValueObject {
        years: number;
        months: number;
        days: number;
        hours: number;
        minutes: number;
        seconds: number;
        firstDateWasLater: boolean;
    }

    interface Moment {
        preciseDiff(d2: Moment, returnValueObject?: false): string;

        preciseDiff(d2: Moment, returnValueObject: true): PreciseRangeValueObject;
    }

    function preciseDiff(d1: Moment, d2: Moment, returnValueObject?: false): string;
    function preciseDiff(d1: Moment, d2: Moment, returnValueObject: true): PreciseRangeValueObject;
}

export interface IDeleteDevice {
    name: string,
    status: boolean,
    platform: string,
    device_id: string
}

interface OwnProps {
    handleClose: () => void
}

type Props = OwnProps;

const AddMobileApp: FunctionComponent<Props> = ({handleClose}) => {

    const CHECK_DEVICE_REQ_TIMEOUT = 1000;
    const TRY_COUNT_LIMIT = 30;
    let tryCount = 0;

    const userAgent = getUserAgent();
    const isMobile = userAgent !== "web";
    const [iosAppReferrer,] = useLocalStorage<TIosAppChoice>('iosApp', undefined);

    // --- запросы
    const {data: userRole} = useGetUserRoleQuery(undefined);
    const password_visible = userRole?.result.password_visible;
    const mpotp_visible = userRole?.result.mpotp_visible;
    const [, {data: segmentData}] = useGetSegmentMutation({fixedCacheKey: 'shared-segment',})

    const [fetchAddDevice, fetchAddDeviceData] = useAddDeviceMutation()
    const [isDeviceAccepted] = useIsDeviceAcceptedMutation()

    const [sendPortalMessage] = useSendPortalMessageMutation()


    // ---
    const [deleteError, setDeleteError] = useState('');
    const [addError, setAddError] = useState('');

    const [addDeviceCode, setAddDeviceCode] = useState("");
    const [addDeviceLoad, setAddDeviceLoad] = useState(false);
    const [successfulDeviceAddition, setSuccessfulDeviceAddition] = useState(false);


    const codeField = useRef<HTMLInputElement>(null);

    const clearSidePanel = () => {
        setAddDeviceCode("")
    }

    async function checkDeviceAccepted(device: INewDevice) {

        const deviceAccepted = await isDeviceAccepted(device);
        if ("data" in deviceAccepted && deviceAccepted.data?.result) {

            // click stream
            try {
                let logInTime = getCookie('logInTime')
                if (!!logInTime) {

                    let durationSuccessRegistrDevice = Number(Date.now() - Number(logInTime));

                    const payload = {
                        message: "Устройства, затраченное время на регистрацию",
                        value: durationSuccessRegistrDevice,
                    }
                    // отправка метрики на наш бэк
                    sendPortalMessage(payload)
                    //
                    sendEvent(
                        {
                            eventCategory: 'Устройства',
                            eventAction: `затраченное время предъявления успешного регистрационного кода, сегмент ${segmentData?.result?.sigma ? "Sigma" : "Internet"}/${userAgent}`,
                            value: durationSuccessRegistrDevice.toString(),
                        }
                    )
                }


            } catch (e) {
                console.log('logInTimeError', e)
            }
            //

            setSuccessfulDeviceAddition(true)
            setAddDeviceLoad(false)
            clearSidePanel()
            setTimeout(() => {
                handleClose()
            }, 2500);

        } else {
            if (tryCount < TRY_COUNT_LIMIT) {
                console.debug("checkDeviceAccepted -> tryCount", tryCount);
                tryCount += 1;
                setTimeout(() => {
                    checkDeviceAccepted(device);
                }, CHECK_DEVICE_REQ_TIMEOUT);
            } else {
                setAddError('Время ожидания добавления устройства истекло, попробуйте еще раз')
                setAddDeviceLoad(false)
                clearSidePanel()
                // setOpenSidePanel(false)
                tryCount = 0;
            }
        }

    }

    const handleAddDevice = async () => {

        setAddDeviceLoad(true)
        // отправка метрик click stream
        sendEvent(
            {
                eventCategory: 'Устройства',
                eventAction: 'боковая панель/клик на кнопку Добавить устройство',
                value: ''
            }
        )
        //
        const res = await fetchAddDevice(addDeviceCode)
        if ("data" in res && res.data?.status === "ok") {

            let newDevice = {
                device_id: res.data.result.device_id,
                platform: res.data.result.platform,
            }

            checkDeviceAccepted(newDevice)

            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Устройства',
                    eventAction: 'боковая панель/успешное предъявление регистрационного кода',
                    value: ''
                }
            )

            if (userAgent === "ios") {
                const a = document.createElement('a');
                a.href = "otpapp://otp"
                a.click();
            }
            if (userAgent === "android") {
                const a = document.createElement('a');
                a.target = "_blank"
                a.href = "sberstore://otp"
                a.click();
            }
        } else {
            setAddDeviceLoad(false)
        }


    }


    useEffect(() => {
        if (fetchAddDeviceData?.error) {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Устройства',
                    eventAction: 'боковая панель/ошибка при Добавлении устройства',
                    value: `${fetchAddDeviceData?.error?.code}: ${fetchAddDeviceData?.error?.message}`,
                }
            )
            //
        }
    }, [fetchAddDeviceData?.error])

    return (
        <>
            <div className={style.step2}>
                {
                    isMobile ? (
                        <Typography style={{marginBottom: "32px"}}>
                            Нам нужно убедиться, что вы - сотрудник Банка, и устройство принадлежит вам
                        </Typography>
                    ) : null
                }
                <div style={{display: "flex"}}>
                    {
                        mpotp_visible && <Typography variant={"h5"} style={{marginBottom: 16, maxWidth: "320px"}}
                                                     className={"content-pre-line"}>
                            Введите регистрационный код из приложения ОТР
                        </Typography>
                    }
                    {
                        password_visible && <Typography variant={"h5"} style={{marginBottom: 16, maxWidth: "320px"}}
                                                        className={"content-pre-line"}>
                            {isMobile ? (iosAppReferrer ? "2. " : "") : `${iosAppReferrer ? "3. " : "2. "}`}Введите
                            регистрационный код из приложения ОТР
                        </Typography>
                    }
                </div>
                <div className={style.actions}>
                    <TextField placeholder={"25YJ4K"}
                               inputRef={codeField}
                               disabled={addDeviceLoad}
                               value={addDeviceCode}
                        //   helperText={fetchAddDeviceData?.error?.message &&
                        //     <span className={"errorText"}
                        //       dangerouslySetInnerHTML={{ __html: fetchAddDeviceData?.error?.message }} />}

                               error={!!fetchAddDeviceData?.error?.message}
                               endIcon={<IconButton onClick={() => setAddDeviceCode("")}
                                                    size={"small"}><CloseIcon/></IconButton>}
                               onKeyDown={(e: any) => {
                                   if (e.key === "Enter" && addDeviceCode.length === 6) {
                                       handleAddDevice()
                                   }
                               }}
                        //   customCounter={`${addDeviceCode.length}/6`}
                               onChange={(e: any) => {
                                   let newVal = e.target.value.replace(/[^a-zA-Z0-9]/g, '').replace(/[0]/g, '').toUpperCase()
                                   if (newVal.length < 7)
                                       setAddDeviceCode(newVal)
                               }}
                    />
                    <Button color={"primary"}
                            loading={addDeviceLoad}
                            onClick={handleAddDevice}
                            disabled={addDeviceCode.length !== 6}
                    >
                        Добавить
                    </Button>
                </div>

                {!!fetchAddDeviceData?.error?.message
                    ? (
                        <Alert severity={"error"}>
                            {fetchAddDeviceData?.error?.message}
                        </Alert>
                    ) : null
                }
            </div>


            <Snackbar
                placement={"top"}
                variant="error"
                open={!!deleteError}
                iconButtonClose
                transitionDuration={300}
                TransitionProps={{}}
                autoHideDuration={7000}
                onClose={() => {
                    setDeleteError('')
                }}
                message={deleteError}
            />
            <Snackbar
                placement={"top"}
                variant="error"
                open={!!addError}
                iconButtonClose
                transitionDuration={300}
                TransitionProps={{}}
                autoHideDuration={7000}
                onClose={() => {
                    setAddError('')
                }}
                message={addError}
            />

            <Snackbar
                placement={"bottom"}
                open={successfulDeviceAddition}
                autoHideDuration={5000}
                transitionDuration={300}
                TransitionProps={{}}
                onClose={() => {
                    setSuccessfulDeviceAddition(false)
                }}
                message={
                    <span style={{whiteSpace: "pre-wrap"}}>
                {`Устройство подтверждено. \nДля продолжения перейдите в приложение OTP`}
            </span>}
            />
        </>
    );
};

export default AddMobileApp;
