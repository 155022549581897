import { Alert, Button, Snackbar, Typography } from "@sber-friend/flamingo-core";
import style from "./MyDevicesIos.module.scss";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { ReactComponent as SberIdIcon } from "../../../assets/img/icons/sberId.svg";
import { ReactComponent as GosUslugiIcon } from "../../../assets/img/icons/gosuslugi.svg";

interface MyDevicesVerifivationProps {
    sberIdLoad: boolean;
    esiaLoad: boolean;
    alertEsia: boolean;
    verified: boolean;
    multiFactorEnabled: boolean | undefined;
    multifactorErrorText: string;
    setAlertEsia: (value: boolean) => void;
    onClickSberId: () => Promise<void>;
    onClickEsia: () => Promise<void>;
}

const MyDevicesVerification = ({
    sberIdLoad,
    esiaLoad,
    alertEsia,
    verified,
    multiFactorEnabled,
    multifactorErrorText,
    setAlertEsia,
    onClickSberId,
    onClickEsia
}: MyDevicesVerifivationProps) => {

    return (
        <>

            {
                multiFactorEnabled && <div className={style.step}>
                    {
                        verified ?
                            <SuccessAlert>Личность подтверждена</SuccessAlert>
                            : (<>
                                <Typography variant={"body1"} className={style.mb20}>
                                    Нам нужно убедиться, что вы сотрудник Банка, и устройство
                                    принадлежит вам.
                                </Typography>
                                <Typography variant={"body1"} className={style.mb16}>
                                    Сверим ваш Сбер ID или номер СНИЛС в Госуслугах. Не храним эти данные и не забираем
                                    остальную информацию из систем.
                                </Typography>
                                <Button variant={"contained"}
                                    startIcon={<SberIdIcon />}
                                    onClick={onClickSberId}
                                    loading={sberIdLoad}
                                    disabled={esiaLoad}
                                    fullWidth
                                    className={style.mb20}
                                >
                                    <Typography variant={"h6"}>Войти по Сбер ID</Typography>
                                </Button>
                                <Button variant={"contained"}
                                    startIcon={<GosUslugiIcon />}
                                    loading={esiaLoad}
                                    disabled={sberIdLoad}
                                    onClick={onClickEsia}
                                    fullWidth
                                >
                                    <Typography variant={"h6"}>Войти через Госуслуги</Typography>
                                </Button>
                            </>)
                    }
                </div>
            }

            {multifactorErrorText &&
                <div className={style.bottomAlert}>
                    <Alert severity={"error"}>{multifactorErrorText}</Alert>
                </div>
            }

            <Snackbar
                placement={"top"}
                open={alertEsia}
                iconButtonClose
                transitionDuration={300}
                TransitionProps={{}}
                autoHideDuration={7000}
                onClose={() => {
                    setAlertEsia(false)
                }}
                message="Загружаем данные из Госуслуг, операция может занять несколько минут"
            />

        </>
    );
};

export default MyDevicesVerification;