import React, {ReactNode} from "react";
import Step1_1 from "../../assets/img/instructions/image_ step 1.1.png";
import Step1_1Mobile from "../../assets/img/instructions/mob step 1.1.png";
import {ReactComponent as ExternalLinkIcon} from "../../assets/img/icons/external-link.svg";
import {ReactComponent as QrMincifrSvg} from "../../assets/img/instructions/qr-mincifr.svg";

import IOSmockup from "../../assets/img/instructions/iOS/iOSMockup.svg";
import AndroidMockup from "../../assets/img/instructions/Android/Mockup android.svg";

import iOSStep3 from "../../assets/img/instructions/iOS/image_ step 3 - ios.png";
import iOSstep3_1 from "../../assets/img/instructions/iOS/image_ step 3.1 - ios.png";
import iOSstep3_2 from "../../assets/img/instructions/iOS/image_ step 3.2 - ios.png";
import iOSstep3_3 from "../../assets/img/instructions/iOS/image_ step 3.3 - ios.png";
import iOSstep3_4 from "../../assets/img/instructions/iOS/image_ step 3.4 - ios.png";
import iOSstep4 from "../../assets/img/instructions/iOS/image_ step 4 - ios.png";

import ios2_1 from "../../assets/img/instructions/iOS/ios2.1.png";
import ios2_2 from "../../assets/img/instructions/iOS/ios2.2.png";
import ios2_3 from "../../assets/img/instructions/iOS/ios2.3.png";
import ios2_4 from "../../assets/img/instructions/iOS/ios2.4.png";
import ios2_5 from "../../assets/img/instructions/iOS/ios2.5.png";

import ios3_1 from "../../assets/img/instructions/iOS/ios3.1.png";
import ios3_2 from "../../assets/img/instructions/iOS/ios3.2.png";
import ios3_3 from "../../assets/img/instructions/iOS/ios3.3.png";

import ios4 from "../../assets/img/instructions/iOS/ios4.png";


import iOSstep1_2 from "../../assets/img/instructions/iOS/image_ step 1.2 - ios.png";
import iOSstep1_3 from "../../assets/img/instructions/iOS/image_ step 1.3 - ios.png";
import iOSstep1_4 from "../../assets/img/instructions/iOS/image_ step 1.4 - ios.png";


import iOSstep1_2Mobile from "../../assets/img/instructions/iOS/image_mob ios step 1.2.png";
import iOSstep1_3Mobile from "../../assets/img/instructions/iOS/image_mob ios step 1.3.png";
import iOSstep1_4Mobile from "../../assets/img/instructions/iOS/image_mob ios step 1.4.png";

import iOSstep2Mobile from "../../assets/img/instructions/iOS/image_mob ios step 2.png";
import iOSstep3Mobile from "../../assets/img/instructions/iOS/image_mob ios step 3.png";
import iOSstep3_1Mobile from "../../assets/img/instructions/iOS/image_mob ios step 3.1.png";
import iOSstep3_2Mobile from "../../assets/img/instructions/iOS/image_mob ios step 3.2.png";
import iOSstep3_3Mobile from "../../assets/img/instructions/iOS/image_mob ios step 3.3.png";
import iOSstep3_4Mobile from "../../assets/img/instructions/iOS/image_mob ios step 3.4.png";
import iOSstep4Mobile from "../../assets/img/instructions/iOS/image_mob ios step 4.png";



import AndroidStep1_2 from "../../assets/img/instructions/Android/image_ step 1.2 - android.png";
import AndroidStep1_3 from "../../assets/img/instructions/Android/image_ step 1.3 - android.png";
import AndroidStep1_4 from "../../assets/img/instructions/Android/image_ step 1.4 - android.png";
import AndroidStep3_1 from "../../assets/img/instructions/Android/3.1.png";
import AndroidStep3_2 from "../../assets/img/instructions/Android/3.2.png";
import AndroidStep3_3 from "../../assets/img/instructions/Android/image_ step 3.3 - android.png";
import AndroidStep3_4 from "../../assets/img/instructions/Android/image_ step 3.4 - android.png";
import AndroidStep4 from "../../assets/img/instructions/Android/image_ step 4 - android.png";

import AndroidStep1_2Mobile from "../../assets/img/instructions/Android/image_mob android step 1.2.png";
import AndroidStep1_3Mobile from "../../assets/img/instructions/Android/image_mob android step 1.3.png";
import AndroidStep1_4Mobile from "../../assets/img/instructions/Android/image_mob android step 1.4.png";
import AndroidStep2Mobile from "../../assets/img/instructions/Android/image_mob android step 2.png";
import AndroidStep3_1Mobile from "../../assets/img/instructions/Android/mob android step 3.1.png";
import AndroidStep3_2Mobile from "../../assets/img/instructions/Android/mob android step 3.2.png";
import AndroidStep3_3Mobile from "../../assets/img/instructions/Android/image_mob android step 3.3.png";
import AndroidStep3_4Mobile from "../../assets/img/instructions/Android/image_mob android step 3.4.png";
import AndroidStep4Mobile from "../../assets/img/instructions/Android/image_mob android step 4.png";
import QRCode from "react-qr-code";
import {getPkingUrl} from "helpers";
import {routesPath} from "../../routes/const";


interface ChildrenContentType {
    title: string | ReactNode;
    subTitle?: string | ReactNode;
    img?: string;
    widePicture?: boolean,
    mobImg?: string;
}

export interface TabContentType {
    menuLabel: string;
    title: string | ReactNode;
    subTitle?: string | ReactNode;
    subTitleMobile?: string | ReactNode;
    img?: string;
    widePicture?: boolean,
    mobImg?: string;
    bgImg?: string;
    qr?: ReactNode;
    children?: Array<ChildrenContentType>
}



export const CyberPassIOSContent: Array<TabContentType> = [
    {
        menuLabel: "Перед установкой",
        title: "Перед установкой проверьте",
        subTitle: "Проверьте смартфон на готовность к работе с сервисом OTP",
        children: [
            {
                title: <>С рабочего компьютера привяжите мобильный телефон в своём профиле <a
                    href='https://addressbook.sigma.sbrf.ru/search/profile'
                    rel="noreferrer"
                    target='_blank'
                    className="green-link">Справочника <ExternalLinkIcon/></a></>,
                img: Step1_1,
                widePicture: true,
                subTitle: "",
                mobImg: Step1_1Mobile,
            },
            {
                title: <>Установите сертификаты <a
                    href='https://www.sberbank.ru/ru/certificates'
                    className="green-link"
                    rel="noreferrer"
                    target='_blank'
                >Минцифры</a></>,
                img: iOSstep1_3,
                mobImg: iOSstep1_3Mobile,
                subTitle: <><QrMincifrSvg/><p>Отсканируйте, чтобы{'\n'} перейти к инструкции {'\n'}на <a
                    href='https://www.sberbank.ru/ru/certificates'
                    className="green-link"
                    rel="noreferrer"
                    target='_blank'>sberbank.ru/ru/certificates <ExternalLinkIcon/></a></p></>,
            },
            {
                title: <>Установите сертификаты <a
                    className="green-link"
                    rel="noreferrer"
                    href={getPkingUrl()} target='_blank'
                >PKING</a></>,
                img: iOSstep1_2,
                mobImg: iOSstep1_2Mobile,
                subTitle: <>
                    <QRCode
                        size={70}
                        style={{height: "auto", maxWidth: "66px", width: "100%", marginRight: 10}}
                        value={getPkingUrl()}
                        viewBox={`0 0 70 70`}
                    />
                    <p>Отсканируйте, чтобы{'\n'} перейти к инструкции {'\n'}на <a
                        className="green-link"
                        rel="noreferrer"
                        href={getPkingUrl()} target='_blank'>pking.sberbank.ru <ExternalLinkIcon/></a>
                    </p>
                </>,
            },
            {
                title: "Установите код-пароль на устройство",
                img: iOSstep1_4,
                mobImg: iOSstep1_4Mobile,
                subTitle: <p>Код-пароль должен состоять из 5 цифр, {'\n'}нельзя использовать последовательность</p>,
            },
        ],
    },
    {
        menuLabel: "Инструкция по установке",
        title: "Следуйте инструкции",
        // subTitle:"Для установки приложения авторизуйтесь и\n" +
        //     "следуйте инструкциям"
    },
]


export const PasswordIOSContent: Array<TabContentType> = [
    {
        menuLabel: "Перед установкой",
        title: "Перед установкой проверьте",
        subTitle: "Проверьте смартфон на готовность к работе с сервисом OTP",
        children: [
            {
                title: <>С рабочего компьютера привяжите мобильный телефон в своём профиле <a
                    href='https://addressbook.sigma.sbrf.ru/search/profile'
                    rel="noreferrer"
                    target='_blank'
                    className="green-link">Справочника <ExternalLinkIcon/></a></>,
                img: Step1_1,
                widePicture: true,
                subTitle: "",
                mobImg: Step1_1Mobile,
            },
            {
                title: <>Установите сертификаты <a
                    href='https://www.sberbank.ru/ru/certificates'
                    className="green-link"
                    rel="noreferrer"
                    target='_blank'
                >Минцифры</a></>,
                img: iOSstep1_3,
                mobImg: iOSstep1_3Mobile,
                subTitle: <><QrMincifrSvg/><p>Отсканируйте, чтобы{'\n'} перейти к инструкции {'\n'}на <a
                    href='https://www.sberbank.ru/ru/certificates'
                    className="green-link"
                    rel="noreferrer"
                    target='_blank'>sberbank.ru/ru/certificates <ExternalLinkIcon/></a></p></>,
            },
            {
                title: <>Установите сертификаты <a
                    className="green-link"
                    rel="noreferrer"
                    href={getPkingUrl()} target='_blank'
                >PKING</a></>,
                img: iOSstep1_2,
                mobImg: iOSstep1_2Mobile,
                subTitle: <>
                    <QRCode
                        size={70}
                        style={{height: "auto", maxWidth: "66px", width: "100%", marginRight: 10}}
                        value={getPkingUrl()}
                        viewBox={`0 0 70 70`}
                    />
                    <p>Отсканируйте, чтобы{'\n'} перейти к инструкции {'\n'}на <a
                        className="green-link"
                        rel="noreferrer"
                        href={getPkingUrl()} target='_blank'>pking.sberbank.ru <ExternalLinkIcon/></a>
                    </p>
                </>,
            },
            {
                title: "Установите код-пароль на устройство",
                img: iOSstep1_4,
                mobImg: iOSstep1_4Mobile,
                subTitle: <p>Код-пароль должен состоять из 5 цифр, {'\n'}нельзя использовать последовательность</p>,
            },
        ],
    },
    {
        menuLabel: "Получите QR-код",
        title: "Получите QR-код",
        subTitle: "Откройте Портал OTP с рабочего компьютера в нужном сегменте",
        children: [
            {
                title: <>Перейдите в раздел «Устройства» <br/>и нажмите «Зарегистрировать»</>,
                img: ios2_1,
            },
            {
                title: "Выберите операционную систему устройства",
                img: ios2_2,
            },
            {
                title: "Выберите приложение «Пароли»",
                img: ios2_3,
            },
            {
                title: "Подтвердите личность через СберID или Госуслуги",
                subTitle: "Нам нужно убедиться, что вы сотрудник Банка, и устройство принадлежит вам.",
                img: ios2_4,
            },
            {
                title: "Выберите сегмент Sigma",
                subTitle: <>Для регистрации в Сигма мы покажем QR-код,<br/> а для Omega — код доступа</>,
                img: ios2_5,
            },
        ]
    },
    {
        menuLabel: "Добавьте ключ в приложение",
        title: "Добавьте ключ в приложение",
        subTitle: "На прошлом шаге вы увидели QR-код",
        children: [
            {
                title: "Отсканируйте QR-код камерой смартфона",
                subTitle: <>После сканирования откроется<br/> приложение «Пароли»</>,
                img: ios3_1,
            },
            {
                title: "Добавьте код проверки",
                subTitle: 'Нажмите " + " и укажите любые данные (для поиска лучше написать в Веб-сайт Sigma/Omega)',
                img: ios3_2,
            },
            {
                title: "Сохраните пароль",
                img: ios3_3,
            },
        ],
    },
    {
        menuLabel: "Как работают «Пароли»",
        title: "Как работают «Пароли»",
        subTitle: <>
            При использовании приложения «Пароли» устройство не добавится в список устройств на Портале OTP.
            <br/><br/>
            Для работы с OTP-кодом, откройте приложение <br/>«Пароли» и перейдите в раздел «Коды» или откройте созданную
            карточку.
            <br/><br/>
            Код проверки – это ваш OTP-код.
        </>,
        img: ios4,
    },
]

export const IOSContent: Array<TabContentType> = [
    {
        menuLabel: "Перед установкой",
        title: "Перед установкой проверьте",
        subTitle: "Проверьте смартфон на готовность к работе с сервисом OTP",
        children: [
            {
                title: <>С рабочего компьютера привяжите мобильный телефон в своём профиле <a
                    href='https://addressbook.sigma.sbrf.ru/search/profile'
                    rel="noreferrer"
                    target='_blank'
                    className="green-link">Справочника <ExternalLinkIcon/></a></>,
                img: Step1_1,
                widePicture: true,
                subTitle: "",
                mobImg: Step1_1Mobile,
            },
            {
                title: <>Установите сертификаты <a
                    href='https://www.sberbank.ru/ru/certificates'
                    className="green-link"
                    rel="noreferrer"
                    target='_blank'
                >Минцифры</a></>,
                img: iOSstep1_3,
                mobImg: iOSstep1_3Mobile,
                subTitle: <><QrMincifrSvg/><p>Отсканируйте, чтобы{'\n'} перейти к инструкции {'\n'}на <a
                    href='https://www.sberbank.ru/ru/certificates'
                    className="green-link"
                    rel="noreferrer"
                    target='_blank'>sberbank.ru/ru/certificates <ExternalLinkIcon/></a></p></>,
            },
            {
                title: <>Установите сертификаты <a
                    className="green-link"
                    rel="noreferrer"
                    href={getPkingUrl()} target='_blank'
                >PKING</a></>,
                img: iOSstep1_2,
                mobImg: iOSstep1_2Mobile,
                subTitle: <>
                    <QRCode
                        size={70}
                        style={{height: "auto", maxWidth: "66px", width: "100%", marginRight: 10}}
                        value={getPkingUrl()}
                        viewBox={`0 0 70 70`}
                    />
                    <p>Отсканируйте, чтобы{'\n'} перейти к инструкции {'\n'}на <a
                        className="green-link"
                        rel="noreferrer"
                        href={getPkingUrl()} target='_blank'>pking.sberbank.ru <ExternalLinkIcon/></a>
                    </p>
                </>,
            },
            {
                title: "Установите код-пароль на устройство",
                img: iOSstep1_4,
                mobImg: iOSstep1_4Mobile,
                subTitle: <p>Код-пароль должен состоять из 5 цифр, {'\n'}нельзя использовать последовательность</p>,
            },
        ],
    },
    {
        menuLabel: "Скачайте приложение",
        title: "Скачайте приложение",
        subTitle: "Отсканируйте QR-код или откройте otp.sberbank.ru на своём устройстве и нажмите кнопку «Скачать приложение».",
        subTitleMobile: "Нажмите кнопку ниже и скачайте приложение",
        img: IOSmockup,
        qr: <QRCode
            size={256}
            style={{height: "auto", maxWidth: "110px", width: "100%", position: "absolute"}}
            value={window?.location?.origin + routesPath.qrCodeDownload}
            viewBox={`0 0 256 256`}
        />,
        mobImg: iOSstep2Mobile,
    },
    {
        menuLabel: "Запустите приложение",
        title: "Запустите приложение",
        subTitle: "Запустите приложение и если вы увидели сообщение «Ненадежный корпоративный разработчик» — добавьте разработчика в надежные, для этого:",
        img: iOSStep3,
        mobImg: iOSstep3Mobile,
        bgImg: IOSmockup,
        children: [
            {
                title: "Перейдите в «Настройки», раздел «Основные»",
                img: iOSstep3_1,
                mobImg: iOSstep3_1Mobile,
            },
            {
                title: "Выберите «VPN и управление устройством»",
                img: iOSstep3_2,
                mobImg: iOSstep3_2Mobile,
            },
            {
                title: "В разделе корпоративные приложения выберите «AIS*********************»",
                img: iOSstep3_3,
                mobImg: iOSstep3_3Mobile,
            },
            {
                title: "В этом разделе нажмите «Доверять»",
                img: iOSstep3_4,
                mobImg: iOSstep3_4Mobile,
            },
        ],
    },
    {
        menuLabel: "Зарегистрируйте устройство",
        title: "Зарегистрируйте устройство",
        subTitle: <>
            Чтобы использовать приложение, зарегистрируйте устройство. Сделать это можно на мобильном устройстве или
            рабочем компьютере.{'\n'}
            <div className="Step4subTitle">
                <div><QRCode
                    size={256}
                    style={{height: "auto", maxWidth: "70px", width: "100%", marginRight: 10}}
                    value={`${window?.location?.origin}/devices`}
                    viewBox={`0 0 256 256`}
                /> Отсканируйте код, чтобы зарегистрировать устройство OTP с мобильного устройства
                </div>
                {'\n'}Для добавления с рабочего компьютера – нажмите на кнопку ниже.
            </div>
        </>,
        img: iOSstep4,
        mobImg: iOSstep4Mobile,
    },
]

export const AndroidContent: Array<TabContentType> = [
    {
        menuLabel: "Перед установкой",
        title: "Перед установкой проверьте",
        subTitle: "Проверьте смартфон на готовность к работе с сервисом OTP",
        children: [
            {
                title: <>С рабочего компьютера привяжите мобильный телефон в своём профиле <a
                    href='https://addressbook.sigma.sbrf.ru/search/profile'
                    rel="noreferrer"
                    target='_blank'
                    className="green-link">Справочника <ExternalLinkIcon/></a></>,
                img: Step1_1,
                widePicture: true,
                mobImg: Step1_1Mobile,
                subTitle: "",
            },
            {
                title: <>Установите сертификаты <a
                    href='https://www.sberbank.ru/ru/certificates'
                    className="green-link"
                    rel="noreferrer"
                    target='_blank'
                >Минцифры</a></>,
                img: AndroidStep1_3,
                mobImg: AndroidStep1_3Mobile,
                subTitle: <><QrMincifrSvg/><p>Отсканируйте, чтобы{'\n'} перейти к инструкции {'\n'}на <a
                    href='https://www.sberbank.ru/ru/certificates'
                    className="green-link"
                    rel="noreferrer"
                    target='_blank'>sberbank.ru/ru/certificates <ExternalLinkIcon/></a></p></>,
            },
            {
                title: <>Установите сертификаты <a
                    className="green-link"
                    rel="noreferrer"
                    href={getPkingUrl()} target='_blank'
                >PKING</a></>,
                img: AndroidStep1_2,
                mobImg: AndroidStep1_2Mobile,
                subTitle: <>
                    <QRCode
                        size={70}
                        style={{height: "auto", maxWidth: "66px", width: "100%", marginRight: 10}}
                        value={getPkingUrl()}
                        viewBox={`0 0 70 70`}
                    />
                    <p>Отсканируйте, чтобы{'\n'} перейти к инструкции {'\n'}на <a
                        className="green-link"
                        rel="noreferrer"
                        href={getPkingUrl()} target='_blank'>pking.sberbank.ru <ExternalLinkIcon/></a>
                    </p>
                </>,
            },
            {
                title: "Установите код-пароль на устройство",
                img: AndroidStep1_4,
                mobImg: AndroidStep1_4Mobile,
                subTitle: <p>Код-пароль должен состоять из 5 цифр, {'\n'}нельзя использовать последовательность</p>,
            },
        ],
    },
    {
        menuLabel: "Скачайте приложение",
        title: "Скачайте приложение",
        subTitle: "Отсканируйте QR-код или откройте otp.sberbank.ru на своём устройстве и нажмите кнопку «Скачать приложение».",
        subTitleMobile: "Нажмите кнопку ниже и скачайте приложение",
        img: AndroidMockup,
        qr: <QRCode
            size={256}
            style={{height: "auto", maxWidth: "110px", width: "100%", position: "absolute"}}
            value={window?.location?.origin + routesPath.qrCodeDownload}
            viewBox={`0 0 256 256`}
        />,
        mobImg: AndroidStep2Mobile,
    },
    {
        menuLabel: "Запустите приложение",
        title: "Запустите приложение",
        subTitle: "",
        //bgImg: AndroidMockup,
        children: [
            {
                title: "Нажмите «Скачать приложение» и откроется окно, в котором нужно выбрать «Настройки». Это сразу перенесет вас в раздел настроек.",
                img: AndroidStep3_1,
                mobImg: AndroidStep3_1Mobile,
            },
            {
                title: "Разрешите вашему браузеру установку приложений и нажмите назад.",
                img: AndroidStep3_2,
                mobImg: AndroidStep3_2Mobile,
            },
            {
                title: "В открывшемся окне нажмите «Установить».",
                img: AndroidStep3_3,
                mobImg: AndroidStep3_3Mobile,
            },
            {
                title: "После завершения установки нажмите «Открыть».",
                img: AndroidStep3_4,
                mobImg: AndroidStep3_4Mobile,
            },
        ],
    },
    {
        menuLabel: "Зарегистрируйте устройство",
        title: "Зарегистрируйте устройство",
        subTitle: <>
            Чтобы использовать приложение, зарегистрируйте устройство. Сделать это можно на мобильном устройстве или
            рабочем компьютере.{'\n'}
            <div className="Step4subTitle">
                <div><QRCode
                    size={256}
                    style={{height: "auto", maxWidth: "70px", width: "100%", marginRight: 10}}
                    value={`${window?.location?.origin}/devices`}
                    viewBox={`0 0 256 256`}
                /> Отсканируйте код, чтобы зарегистрировать устройство OTP с мобильного устройства
                </div>
                {'\n'}Для добавления с рабочего компьютера – нажмите на кнопку ниже.
            </div>
        </>,
        img: AndroidStep4,
        mobImg: AndroidStep4Mobile,
    },
]