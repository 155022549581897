import {useState} from "react";
import {
    Alert,
    Box,
    Button,
    Radio,
    Typography,
    makeStyles
} from "@sber-friend/flamingo-core";
import {ReactComponent as PDFIcon} from "../../../assets/img/icons/icon-pdf.svg";
import {AndroidIcon, IosIcon} from "@sber-friend/flamingo-icons";
import {useEffectOnce, useLocalStorage} from "usehooks-ts";
import SidePanel from "components/SidePanel/SidePanel";
import VerificationSigma from "./VerificationSigma";
import MyDevicesIosAdd from "./MyDevicesIosAdd";
import {useGetUserRoleQuery, useMultiFactorVerifyMutation} from "api/devices";
import {getUserAgent} from "helpers";
import {ListItem} from "@sber-friend/flamingo-components";
import {useSearchParams} from "react-router-dom";
import AddMobileApp from "./AddMobileApp";

interface MyDevicesSidePanelProps {
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
}

type TPlatformChoice = "ios" | "android" | undefined;
export type TIosAppChoice = "passwords" | "otp" | undefined;

const INSTRUCTION_URL = "https://otp.sberbank.ru/instructions/IOS_qr_instruction.pdf";

const useStyles = makeStyles({
    Alert: {
        backgroundColor: "#21A03814",

        "& > div:first-child": {
            display: "none"
        }
    }
});

const userAgent = getUserAgent();

const MyDevicesSidePanel = ({
                                open,
                                onOpen,
                                onClose
                            }: MyDevicesSidePanelProps) => {
    const classes = useStyles();

    const isMobileDevice = userAgent !== "web";

    const [, {
        data: multiFactorVerifyData,
        reset: multiFactorVerifyReset
    }] = useMultiFactorVerifyMutation({fixedCacheKey: 'shared-mutifactor'});

    const isVerifyed = Boolean(multiFactorVerifyData?.result?.verified);
    const [searchParams] = useSearchParams();

    const [userPlatformReferrer, setUserPlatformReferrer] = useLocalStorage<TPlatformChoice>('userPlatform', undefined);
    const [iosAppReferrer, setIosAppReferrer] = useLocalStorage<TIosAppChoice>('iosApp', undefined);
    const [userPlatfrom, setUserPlatform] = useState<TPlatformChoice>(userPlatformReferrer);
    const [iosApp, setIosApp] = useState<TIosAppChoice>(iosAppReferrer);


    const {data: userRole} = useGetUserRoleQuery(undefined);
    const multiFactorEnabled = userRole?.result.ui_settings.multifactorEnabled;
    const password_visible = userRole?.result.password_visible;
    const mpotp_visible = userRole?.result.mpotp_visible;


    const isPlatformSelected = userPlatformReferrer !== undefined || isMobileDevice;
    const isIos = userPlatformReferrer === "ios" || userAgent === "ios";
    const isAndroid = userPlatformReferrer === "android" || userAgent === "android";

    const isVerifyBlockVisible = isPlatformSelected && multiFactorEnabled && isIos && iosAppReferrer === "passwords";
    const isPlatformBlockVisible = isPlatformSelected && (isAndroid || (isVerifyed || (iosAppReferrer && !multiFactorEnabled)) || iosAppReferrer === "otp")

    const onMount = () => {
        if (!searchParams?.toString()) {
            setIosAppReferrer(undefined)
            setUserPlatformReferrer(undefined)
            setUserPlatform(undefined)
        }
    }

    useEffectOnce(() => {
        onMount()
    })

    const handlePlatformSelect = (userPlatform: TPlatformChoice) => {
        setUserPlatform(userPlatform);
    }

    const handleIosAppSelect = (userPlatform: TIosAppChoice) => {
        setIosApp(userPlatform);
    }

    const handleClose = () => {
        setIosAppReferrer(undefined)
        setUserPlatformReferrer(undefined);
        setUserPlatform(undefined);
        multiFactorVerifyReset();
        onClose();
    }

    const handleNextClick = (userPlatform: TPlatformChoice) => {
        setUserPlatformReferrer(userPlatform);
    }

    const iosInstructionBlock = userAgent === "ios" ? (
        <ListItem
            style={{paddingLeft: 0, paddingBottom: "24px"}}
            icon={<PDFIcon/>}
            key="with-icon-item-1"
            primaryText="Инструкция по установке"
            secondaryText="в формате PDF"
            action={
                <Button
                    href={INSTRUCTION_URL}
                    target={"_blank"}
                >
                    Открыть
                </Button>}
        />
    ) : null

    const selectPlatformBlock = (
        <Box pb={4}>
            <Typography variant="h5">
                1. Выберите операционную систему
            </Typography>

            <Box mt={2}>
                {
                    isPlatformSelected
                        ? (
                            <>
                                <Alert severity="success" className={classes.Alert}>
                                    <Box display={"flex"} alignItems={"center"}>
                                        {
                                            userPlatformReferrer === "ios"
                                                ? <IosIcon color="primary"/>
                                                : <AndroidIcon color="primary"/>
                                        }
                                        <Typography
                                            style={{marginLeft: "12px"}}
                                            color="primary"
                                            children={userPlatformReferrer === "ios" ? "iOS" : "Android"}
                                        />
                                    </Box>
                                </Alert>
                            </>
                        )
                        : (
                            <>
                                <Box display="flex" pb={2}>
                                    <Box ml={-1}>
                                        <Radio
                                            margin="none"
                                            label={"iOS"}
                                            checked={userPlatfrom === "ios"}
                                            onChange={() => handlePlatformSelect("ios")}
                                        />
                                    </Box>
                                    <Box ml={4}>
                                        <Radio
                                            margin="none"
                                            label={"Android"}
                                            checked={userPlatfrom === "android"}
                                            onChange={() => handlePlatformSelect("android")}
                                        />
                                    </Box>
                                </Box>

                                <Box>
                                    <Button
                                        color={"primary"}
                                        disabled={!userPlatfrom}
                                        onClick={() => handleNextClick(userPlatfrom)}
                                    >
                                        Дальше
                                    </Button>

                                </Box>
                            </>
                        )
                }
            </Box>
        </Box>
    )

    const selectIosAppBlock = (isIos ?
            <Box pb={4}>
                <Typography variant="h5">
                    {isMobileDevice ? "1. Выберите приложение" : "2. Выберите приложение"}
                </Typography>

                <Box mt={2}>
                    {
                        iosAppReferrer
                            ? (
                                <>
                                    <Alert severity="success" className={classes.Alert}>
                                        <Box display={"flex"} alignItems={"center"}>
                                            {/*{*/}
                                            {/*    userPlatformReferrer === "ios"*/}
                                            {/*        ? <IosIcon color="primary" />*/}
                                            {/*        : <AndroidIcon color="primary" />*/}
                                            {/*}*/}
                                            <Typography
                                                style={{marginLeft: "12px"}}
                                                color="primary"
                                                children={iosAppReferrer === "otp" ? "OTP" : "Пароли"}
                                            />
                                        </Box>
                                    </Alert>
                                </>
                            )
                            : (
                                <>
                                    <Box display="flex" pb={2}>
                                        <Box ml={-1}>
                                            <Radio
                                                margin="none"
                                                label={"Пароли"}
                                                checked={iosApp === "passwords"}
                                                onChange={() => handleIosAppSelect("passwords")}
                                            />
                                        </Box>
                                        <Box ml={4}>
                                            <Radio
                                                margin="none"
                                                label={"OTP"}
                                                checked={iosApp === "otp"}
                                                onChange={() => handleIosAppSelect("otp")}
                                            />
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Button
                                            color={"primary"}
                                            disabled={!iosApp}
                                            onClick={() => setIosAppReferrer(iosApp)}
                                        >
                                            Дальше
                                        </Button>

                                    </Box>
                                </>
                            )
                    }
                </Box>
            </Box> : null
    )

    const verificationBlock = isVerifyBlockVisible
        ? (
            <Box>
                <Typography variant="h5" style={{marginBottom: "20px"}}>
                    {isMobileDevice ? 2 : 3}. Подтвердите личность
                </Typography>

                <VerificationSigma
                    openSidePanel={open}
                    handleOpenSidePanel={onOpen}
                    handleCloseSidePanel={onClose}
                />
            </Box>
        ) : null;

    const iosBlock = (
        <Box pb={4}>
            <Typography variant="h5" style={{marginBottom: "20px"}}>
                {`${isVerifyBlockVisible && !isMobileDevice ? 4 : !multiFactorEnabled && isMobileDevice ? 2 : 3}`}.
                Выберите сегмент
            </Typography>
            <MyDevicesIosAdd/>
        </Box>
    );

    const platformBlock = (isIos && iosAppReferrer === "passwords")
        ? iosBlock
        : <AddMobileApp handleClose={handleClose}/>;

    const desktopBlock = (
        <>
            {selectPlatformBlock}
            {selectIosAppBlock}
            {verificationBlock}
            {
                isPlatformBlockVisible
                    ? platformBlock
                    : null
            }
        </>
    );

    const mobileDeviceBlock = (
        <>
            {iosInstructionBlock}
            {selectIosAppBlock}
            {verificationBlock}
            {
                isPlatformBlockVisible
                    ? platformBlock
                    : null
            }
        </>
    );

    return (
        <SidePanel
            open={open}
            onOpen={onOpen}
            onClose={handleClose}
            header={"Зарегистрировать устройство"}
        >
            {mpotp_visible && <AddMobileApp handleClose={handleClose}/>}
            {password_visible && (isMobileDevice ? mobileDeviceBlock : desktopBlock)}
        </SidePanel>
    )
};

export default MyDevicesSidePanel;
