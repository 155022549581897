import { useEffect, useCallback, useState } from 'react';
import { useCountdown } from 'usehooks-ts';
import { Box, Typography, CircularProgress, Snackbar } from '@sber-friend/flamingo-core';
import { copyToClipboard } from 'helpers';

interface OTPBoxProps {
    otp: string;
    secondsRemain: number;
    onTimerOut: () => void
}

interface ISnackbar {
    isVisible: boolean;
    isError: boolean;
    message: string;
}

const MAX_TIME_SECONDS = 30;
const TIMER_SMOOTH_INDEX = 16;

function getColor(seconds: number) {
    if (seconds <= 3) {
        return 'error';
    } 
    
    if (seconds <= 10) {
        return 'warning';
    } 
    
    return 'primary';
};


const OTPBox = ({
    otp,
    secondsRemain,
    onTimerOut
}: OTPBoxProps) => {

    const [snackbar, setSnackbar] = useState<ISnackbar>({
        isVisible: false,
        isError: false,
        message: ''
    });

    const [count, { startCountdown }] = useCountdown({ 
        countStart: secondsRemain * TIMER_SMOOTH_INDEX,
        intervalMs: 1000 / TIMER_SMOOTH_INDEX,
    });

    const smoothedCount = count / TIMER_SMOOTH_INDEX;
    const progress = smoothedCount / MAX_TIME_SECONDS * 100;

    const color = getColor(smoothedCount);

    useEffect(() => {
        if (secondsRemain) {
            startCountdown();
        }
    }, [secondsRemain, startCountdown]);

    useEffect(() => {
        if (smoothedCount === 0) {
            onTimerOut();
        }
    }, [smoothedCount, onTimerOut])

    const copyToClipboardWithMessage = useCallback(async (text: string) => {
        try {
            await copyToClipboard(text);
            setSnackbar({ 
                isVisible: true, 
                isError: false,
                message: "OTP скопирован в буфер обмена" 
            });
        } catch (_) {
            setSnackbar({ 
                isVisible: true, 
                isError: true,
                message: "Не удалось скопировать OTP" 
            });
        }
    }, []);

    return (
        <>
            <Box mb={2.5}>
                <Typography 
                    align="center"
                    variant="body1" 
                    color="textSecondary"
                >
                    Секунд до истечения срока действия OTP:
                </Typography>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                position="relative"
                mb={4}
            >
                <CircularProgress
                    size={64}
                    variant="determinate"
                    value={progress}
                    color={color}
                />
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    style={{ transform: "translate(-50%, -50%)" }}
                >
                    <Typography variant="body1">
                        {Math.floor(smoothedCount)}
                    </Typography>
                </Box>
            </Box>

            <Box
                display="flex"
                justifyContent="center"
                style={{ cursor: "pointer" }}
                onClick={() => copyToClipboardWithMessage(otp)}
            >
                {otp.split('').map((digit, i) => {
                    return (
                        <Typography
                            key={i}
                            color={color}
                            variant="display2_1"
                            style={{ marginRight: i === 2 ? "16px" : "0px" }}
                        >
                            {digit}
                        </Typography>
                    )
                })}
            </Box>

            <Snackbar
                placement={"top"}
                autoHideDuration={2000}
                iconButtonClose={false}
                open={snackbar.isVisible}
                variant={snackbar.isError ? "error" : "default"}
                message={snackbar.message}
                transitionDuration={300}
                TransitionProps={{}}
                onClose={() => {
                    setSnackbar({ 
                        isVisible: false, 
                        isError: false,
                        message: "" 
                    });
                }}
            />
        </>
    )
};

export default OTPBox;
