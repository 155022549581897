import { useEffect } from 'react';
import { useCountdown } from 'usehooks-ts';
import { Typography } from '@sber-friend/flamingo-core';

interface OTPBoxProps {
    secondsRemain: number;
    onTimerOut: () => void
}

const MyDevicesCoundown = ({
    secondsRemain,
    onTimerOut
}: OTPBoxProps) => {
    const [count, { startCountdown }] = useCountdown({
        countStart: secondsRemain,
        intervalMs: 1000,
    });

    useEffect(() => {
        if (secondsRemain) {
            startCountdown();
        }
    }, [secondsRemain, startCountdown]);

    useEffect(() => {
        if (count === 0) {
            onTimerOut();
        }
    }, [count, onTimerOut])

    return (
        <Typography>
            Секунд до истечения срока действия: <span style={{ fontWeight: 600 }}>{count}</span>
        </Typography>
    )
};

export default MyDevicesCoundown;
