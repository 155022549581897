import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import {
    Alert,
    Button,
    TextField,
    Typography,
} from "@sber-friend/flamingo-core";
import style from "./HardwareToken.module.scss";
import SidePanel from "../../../components/SidePanel/SidePanel";
import {useEffectOnce, useLocalStorage} from "usehooks-ts";
import {ReactComponent as SberIdIcon} from "../../../assets/img/icons/sberId.svg";
import {ReactComponent as GosUslugiIcon} from "../../../assets/img/icons/gosuslugi.svg";
import SuccessAlert from '../../../components/SuccessAlert/SuccessAlert';
import {
    useActivateOmegaTokenMutation,
    useGetMultiFactorUrlMutation, useGetUserRoleQuery,
    useMultiFactorVerifyMutation
} from "../../../api/devices";
import {routesPath} from "../../../routes/const";
import {useNavigate, useSearchParams} from "react-router-dom";
import tokenFrontImg from "../../../assets/img/Devices/tokenFront.png"
import tokenBackImg from "../../../assets/img/Devices/tokenBack.png"
import {sendEvent} from '@sbol/clickstream-agent/sdk';
import Snackbar from '@sber-friend/flamingo-core/Snackbar';


interface OwnProps {
    openSidePanel: boolean,
    handleOpenSidePanel: () => void,
    handleCloseSidePanel: () => void
}

type Props = OwnProps;

const HardwareTokenSigma: FunctionComponent<Props> = ({openSidePanel, handleCloseSidePanel, handleOpenSidePanel}) => {

    const ErrorMultifactorServer = "exc=602";
    const alertEsiaAutoOpen = useRef<ReturnType<typeof setTimeout>>();


    const navigate = useNavigate();
    const [searchParams] = useSearchParams();


    // --- запросы

    const [getMultiFactorUrl] = useGetMultiFactorUrlMutation()


    const [multiFactorVerify, {
        data: multiFactorVerifyData,
        error: multiFactorVerifyError,
    }] = useMultiFactorVerifyMutation()

    const [fetchActivateToken, {
        isLoading: loadingActivateToken,
        error: activateTokenError,
        isSuccess: activateTokenSuccess,
    }] = useActivateOmegaTokenMutation()

    const {data: userRole} = useGetUserRoleQuery(undefined);


    // ---


    const [serialNumberField, setSerialNumberField] = useState("");


    const [, setEsiaTokenSigmaReferrer] = useLocalStorage('esiaTokenSigmaReferrer', false);
    const [, setSberIdTokenSigmaReferrer] = useLocalStorage('sberIdTokenSigmaReferrer', false);

    const [isEsiaTokenReferrer, setEsiaTokenOmegaReferrer] = useLocalStorage('esiaTokenOmegaReferrer', false);
    const [isSberIdTokenReferrer, setSberIdTokenOmegaReferrer] = useLocalStorage('sberIdTokenOmegaReferrer', false);

    const [multiFactorErrorMessage, setMultiFactorErrorMessage] = useState("");
    const [esiaLoad, setEsiaLoad] = useState(false);
    const [sberIdLoad, setSberIdLoad] = useState(false);
    const [alertEsia, setAlertEsia] = useState(false);

    const [imageSnackBar1, setImageSnackBar1] = useState(false);
    const [imageSnackBar2, setImageSnackBar2] = useState(false);

    const multiFactorEnabled = userRole?.result.ui_settings.multifactorEnabled;


    const conditionActivateToken = (serialNumberField.length === 10);


    const clearReferrer = () => {
        setSberIdTokenOmegaReferrer(false)
        setEsiaTokenOmegaReferrer(false)
        setEsiaTokenSigmaReferrer(false)
        setSberIdTokenSigmaReferrer(false)
    }


    const handleClickSberId = async () => {


        clearReferrer()
        setSberIdTokenOmegaReferrer(true)
        setSberIdLoad(true)
        const response = await getMultiFactorUrl('sber-id')
        if ("data" in response) {
            const {url} = response.data.result
            const a = document.createElement('a');
            a.href = url;
            a.click();
        }
        setSberIdLoad(false)
    }

    const handleClickEsia = async () => {
        clearReferrer()
        setEsiaTokenOmegaReferrer(true)
        setEsiaLoad(true)
        const response = await getMultiFactorUrl('esia')
        if ("data" in response) {
            const {url} = response.data.result;
            const a = document.createElement('a');
            a.href = url;
            a.click();
        }
        setEsiaLoad(false)
    }


    const handleClickActivateToken = async () => {

        const res = await fetchActivateToken({
            serial: serialNumberField
        })

        if ("data" in res && res.data.status === "ok") {

            //
            setTimeout(() => {

            }, 1500)
        }
    }


    useEffect(() => {
        if (activateTokenError) {
            // отправка метрик click stream
            sendEvent(
                {
                    eventCategory: 'Регистрация токена',
                    eventAction: 'клик на кнопку Активировать/неуспешная регистрация',
                    value: `${activateTokenError?.code}: ${activateTokenError?.message}`,
                }
            )
            //
        }
    }, [activateTokenError])


    const onMount = async () => {
        if (searchParams.toString() && (isEsiaTokenReferrer || isSberIdTokenReferrer) && multiFactorEnabled) {
            if (searchParams.toString() === ErrorMultifactorServer) {
                handleOpenSidePanel();
                setMultiFactorErrorMessage("Сервер временно недоступен, попробуйте позднее");
                navigate(routesPath.devices);
            } else {
                if (isEsiaTokenReferrer) {
                    setEsiaLoad(true)
                    alertEsiaAutoOpen.current = setTimeout(() => {
                        setAlertEsia(true)
                    }, 35000)
                }
                if (isSberIdTokenReferrer) {
                    setSberIdLoad(true)
                }
                // Открываем сайдпанель
                handleOpenSidePanel();

                // читаем квери парметры и делаем мультифакторную проверку
                const params = Object.fromEntries(searchParams.entries());
                navigate(routesPath.devices)
                await multiFactorVerify(params)
                clearTimeout(alertEsiaAutoOpen.current);
                setAlertEsia(false)
                setEsiaLoad(false)
                setSberIdLoad(false)
            }
            clearReferrer()
        }

    }


    useEffectOnce(() => {
        onMount()
    })

    return (
        <>
            <SidePanel open={openSidePanel}
                       onOpen={handleOpenSidePanel}
                       onClose={handleCloseSidePanel}
                       header={"Зарегистрировать токен в Омеге"}
            >
                {
                    multiFactorEnabled && <div className={style.step}>
                        <Typography variant={"h5"} className={style.mb16}>
                            1. Подтвердите личность
                        </Typography>
                        {
                            multiFactorVerifyData?.result?.verified ?
                                <SuccessAlert>Личность подтверждена</SuccessAlert>
                                : (<>
                                    <Typography variant={"body1"} className={style.mb20}>
                                        Нам нужно убедиться, что вы сотрудник Банка, и устройство
                                        принадлежит вам.
                                    </Typography>
                                    <Typography variant={"body1"} className={style.mb16}>
                                        Сверим ваш Сбер ID или номер СНИЛС в Госуслугах. Не храним эти данные и не забираем
                                        остальную информацию из систем.
                                    </Typography>
                                    <Button variant={"contained"}
                                            startIcon={<SberIdIcon/>}
                                            onClick={handleClickSberId}
                                            loading={sberIdLoad}
                                            disabled={esiaLoad}
                                            fullWidth
                                            className={style.mb20}
                                    >
                                        <Typography variant={"h6"}>Войти по Сбер ID</Typography>
                                    </Button>
                                    <Button variant={"contained"}
                                            startIcon={<GosUslugiIcon/>}
                                            loading={esiaLoad}
                                            disabled={sberIdLoad}
                                            onClick={handleClickEsia}
                                            fullWidth
                                    >
                                        <Typography variant={"h6"}>Войти через Госуслуги</Typography>
                                    </Button>
                                </>)
                        }
                    </div>
                }

                {(multiFactorErrorMessage || multiFactorVerifyError?.message) &&
                    <div className={style.bottomAlert}>
                        <Alert severity={"error"}>{multiFactorErrorMessage || multiFactorVerifyError?.message}</Alert>
                    </div>
                }
                {
                    (multiFactorVerifyData?.result?.verified || !multiFactorEnabled) && <div className={style.step}>
                        <Typography variant={"h5"} className={style.mb16}>
                            {multiFactorEnabled ? "2" : "1"}. Введите серийный номер токена
                        </Typography>
                        {
                            activateTokenSuccess ? <SuccessAlert>Серийный номер сохранен</SuccessAlert>
                                : <>
                                    <Typography variant={"body1"} className={style.mb16}>
                                        Чтобы мы определили, какой токен принадлежит вам
                                    </Typography>
                                    <div className={style.field}>
                                        <TextField placeholder={"1234567890"}
                                                   value={serialNumberField}
                                                   onChange={(e) => {
                                                       let newVal = e.target.value.replace(/[^0-9]/g, '')
                                                       if (newVal.length < 11)
                                                           setSerialNumberField(newVal)
                                                   }}
                                                   onKeyDown={(e) => {
                                                       if (e.key === "Enter" && conditionActivateToken) {
                                                           handleClickActivateToken()
                                                       }
                                                   }}
                                                   label={"Серийный номер"}
                                                   customCounter={`${serialNumberField.length}/10`}
                                                   required
                                        />
                                        <Typography variant={"body2"}>
                                            <span className="green-link"
                                                  onClick={() => setImageSnackBar1(true)}>Cерийный номер</span>
                                            &nbsp;— 10 цифр на обороте токена
                                        </Typography>
                                    </div>
                                    <Typography variant={"body1"} className={style.mb16}>
                                        Когда нажмёте «Сохранить», у вас будет 10 минут для регистрации токена на портале в
                                        Omega. Информация о вашем логине и пароле Omega — в <a
                                        rel="noreferrer"
                                        href="https://sberu.sigma.sbrf.ru/SberWelcome" target={"_blank"}
                                        className="green-link">Моих
                                        доступах</a>
                                    </Typography>
                                    <Button color={"primary"}
                                            className={style.mobileBtn}
                                            disabled={!conditionActivateToken}
                                            loading={loadingActivateToken}
                                            onClick={handleClickActivateToken}>
                                        Сохранить
                                    </Button>
                                </>
                        }
                    </div>
                }
                {
                    (multiFactorVerifyData?.result?.verified || !multiFactorEnabled) && activateTokenSuccess &&
                    <div className={style.step}>
                        <Typography variant={"h5"} className={style.mb16}>
                            {multiFactorEnabled ? "3" : "2"}. Перейдите на портал ОТР в Omega
                        </Typography>
                        <Typography variant={"body1"} className={style.mb16}>
                            У вас есть 10 минут для регистрации токена на портале в Omega. Зайдите на otp.omega.sbrf.ru
                            → Устройства → Токен.
                        </Typography>
                    </div>
                }
                {activateTokenError?.message &&
                    <div className={style.bottomAlert}>
                        <Alert severity={"error"}>{activateTokenError?.message}</Alert>
                    </div>
                }
                <Snackbar
                    placement={"bottom"}
                    hideIcon
                    open={imageSnackBar1}
                    iconButtonClose
                    transitionDuration={300}
                    TransitionProps={{}}
                    onClose={() => {
                        setImageSnackBar1(false)
                    }}
                    message={<img src={tokenBackImg} alt="tokenBackImg"/>}
                />
                <Snackbar
                    placement={"bottom"}
                    hideIcon
                    open={imageSnackBar2}
                    iconButtonClose
                    transitionDuration={300}
                    TransitionProps={{}}
                    onClose={() => {
                        setImageSnackBar2(false)
                    }}
                    message={<img src={tokenFrontImg} alt="tokenFrontImg"/>}
                />
                <Snackbar
                    placement={"top"}
                    open={alertEsia}
                    iconButtonClose
                    transitionDuration={300}
                    TransitionProps={{}}
                    autoHideDuration={7000}
                    onClose={() => {
                        setAlertEsia(false)
                    }}
                    message="Загружаем данные из Госуслуг, операция может занять несколько минут"
                />
            </SidePanel>
        </>
    );
};

export default HardwareTokenSigma;
