import { useRef, useState, useEffect } from "react";
import { useGetMultiFactorUrlMutation, useGetUserRoleQuery, useMultiFactorVerifyMutation } from "api/devices";
import SuccessAlert from "components/SuccessAlert/SuccessAlert";
import { Alert, Button, Snackbar, Typography } from "@sber-friend/flamingo-core";
import style from "./OTPVerification.module.scss";
import { ReactComponent as SberIdIcon } from "../../../assets/img/icons/sberId.svg";
import { ReactComponent as GosUslugiIcon } from "../../../assets/img/icons/gosuslugi.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffectOnce, useLocalStorage } from "usehooks-ts";
import { routesPath } from "../../../routes/const";
import SidePanel from "components/SidePanel/SidePanel";

interface OTPVerificationProps {
  openSidePanel: boolean;
  handleOpenSidePanel: () => void;
  handleCloseSidePanel: () => void;
}

const ErrorMultifactorServer = "exc=602";

const OTPVerification = ({
  openSidePanel,
  handleOpenSidePanel,
  handleCloseSidePanel
}: OTPVerificationProps) => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const alertEsiaAutoOpen = useRef<ReturnType<typeof setTimeout>>();

  const [isEsiaOtpReferrer, setEsiaOtpReferrer] = useLocalStorage('esiaOmegaReferrer', false);
  const [isSberIdOtpReferrer, setSberIdOtpReferrer] = useLocalStorage('sberIdOmegaReferrer', false);
  
  // const [isSberIdOtpReferrer, setSberIdOtpReferrer] = useLocalStorage('sberIdOmegaReferrer', false);
  
  const [sberIdLoad, setSberIdLoad] = useState(false);
  const [esiaLoad, setEsiaLoad] = useState(false);
  const [alertEsia, setAlertEsia] = useState(false);
  const [multiFactorErrorMessage, setMultiFactorErrorMessage] = useState("");

  const [getMultiFactorUrl] = useGetMultiFactorUrlMutation()

  const { data: userRole } = useGetUserRoleQuery(undefined);

  const [multiFactorVerify, {
    data: multiFactorVerifyData,
    error: multiFactorVerifyError,
    // reset: multiFactorVerifyReset
  }] = useMultiFactorVerifyMutation({ fixedCacheKey: 'shared-mutifactor-otp' });


  const verified = Boolean(multiFactorVerifyData?.result?.verified)

  const multifactorErrorText = multiFactorErrorMessage || multiFactorVerifyError?.message || "";
  const multiFactorEnabled = userRole?.result.ui_settings.multifactorEnabled;

  useEffect(() => {
    if (openSidePanel && verified) {
      handleCloseSidePanel();
    }
  }, [openSidePanel, verified, handleCloseSidePanel])

  const clearReferrer = () => {
    setEsiaOtpReferrer(false);
    setSberIdOtpReferrer(false);
  }

  const handleClickSberId = async () => {

    // отправка метрик click stream
    // update Events model
    // sendEvent(
    //     {
    //         eventCategory: 'Регистрация токена',
    //         eventAction: 'клик на кнопку  "Войти по Сбер ID"',
    //         value: ''
    //     }
    // )
    //
    clearReferrer()
    setSberIdOtpReferrer(true)
    setSberIdLoad(true);

    const response = await getMultiFactorUrl('sber-id')
    if ("data" in response) {
      const { url } = response.data.result
      const a = document.createElement('a');
      a.href = url;
      a.click();
    }
    setSberIdLoad(false);
  }


  const handleClickEsia = async () => {

    // отправка метрик click stream
    // update Events model
    // sendEvent(
    //     {
    //         eventCategory: 'Регистрация токена',
    //         eventAction: 'клик на кнопку  "Войти через Госуслуги"',
    //         value: ''
    //     }
    // )
    //
    clearReferrer()
    setEsiaOtpReferrer(true);
    setEsiaLoad(true)

    const response = await getMultiFactorUrl('esia')
    if ("data" in response) {
      const { url } = response.data.result;
      const a = document.createElement('a');
      a.href = url;
      a.click();
    }
    setEsiaLoad(false)
  }

  const onMount = async () => {
    if (searchParams.toString() && (isEsiaOtpReferrer || isSberIdOtpReferrer) && multiFactorEnabled) {
      if (searchParams.toString() === ErrorMultifactorServer) {
        handleOpenSidePanel();
        setMultiFactorErrorMessage("Сервер временно недоступен, попробуйте позднее");
        navigate(routesPath.devices);
      } else {
        if (isEsiaOtpReferrer) {
          setEsiaLoad(true)
          alertEsiaAutoOpen.current = setTimeout(() => {
            setAlertEsia(true)
          }, 35000)
        }
        if (isSberIdOtpReferrer) {
          setSberIdLoad(true)
        }
        // Открываем сайдпанель
        handleOpenSidePanel();

        // читаем квери парметры и делаем мультифакторную проверку
        const params = Object.fromEntries(searchParams.entries());
        navigate(routesPath.devices)
        await multiFactorVerify(params)
        //   .then((res) => {
        //     if ("data" in res) {
        //         onVerify(res.data.result?.verified)
        //     }
        //   })
        clearTimeout(alertEsiaAutoOpen.current);
        setAlertEsia(false)
        setEsiaLoad(false)
        setSberIdLoad(false)
      }
      clearReferrer()
    }
  }


  useEffectOnce(() => {
    onMount()
  })

  return (
    <SidePanel open={openSidePanel}
      onOpen={handleOpenSidePanel}
      onClose={handleCloseSidePanel}
      header={"Подтверждение личности"}
    >

      {
        multiFactorEnabled && <div className={style.step}>
          {
            verified ?
              <SuccessAlert>Личность подтверждена</SuccessAlert>
              : (<>
                <Typography variant={"body1"} className={style.mb20}>
                  Нам нужно убедиться, что вы сотрудник Банка, и устройство
                  принадлежит вам.
                </Typography>
                <Typography variant={"body1"} className={style.mb16}>
                  Сверим ваш Сбер ID или номер СНИЛС в Госуслугах. Не храним эти данные и не забираем
                  остальную информацию из систем.
                </Typography>
                <Button variant={"contained"}
                  startIcon={<SberIdIcon />}
                  onClick={handleClickSberId}
                  loading={sberIdLoad}
                  disabled={esiaLoad}
                  fullWidth
                  className={style.mb20}
                >
                  <Typography variant={"h6"}>Войти по Сбер ID</Typography>
                </Button>
                <Button variant={"contained"}
                  startIcon={<GosUslugiIcon />}
                  loading={esiaLoad}
                  disabled={sberIdLoad}
                  onClick={handleClickEsia}
                  fullWidth
                >
                  <Typography variant={"h6"}>Войти через Госуслуги</Typography>
                </Button>
              </>)
          }
        </div>
      }

      {multifactorErrorText &&
        <div className={style.bottomAlert}>
          <Alert severity={"error"}>{multifactorErrorText}</Alert>
        </div>
      }

      <Snackbar
        placement={"top"}
        open={alertEsia}
        iconButtonClose
        transitionDuration={300}
        TransitionProps={{}}
        autoHideDuration={7000}
        onClose={() => {
          setAlertEsia(false)
        }}
        message="Загружаем данные из Госуслуг, операция может занять несколько минут"
      />

    </SidePanel>
  );
};

export default OTPVerification;
