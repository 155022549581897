import React, {FunctionComponent, useEffect, useRef, useState} from 'react';
import style from "./InstructionsPage.module.scss"
import {Button, CircularLoader, Dialog, Tab, Tabs, Tooltip, Typography} from "@sber-friend/flamingo-core";
import InstructionTab, {InstructionTabHandles} from "./InstructionTab/InstructionTab";
import {AndroidContent, CyberPassIOSContent, IOSContent, PasswordIOSContent} from "./Content";
import SideBar from "./SideBar/SideBar";
import {useWindowSize} from "usehooks-ts";
import {AndroidIcon, ExternalLinkIcon, IosIcon} from "@sber-friend/flamingo-icons";
import {useGetSettingsQuery} from "../../api/main";
import {getUserAgent} from "../../helpers";

interface OwnProps {
}

type Props = OwnProps;

const InstructionsPage: FunctionComponent<Props> = () => {

    const {isLoading: getSettingsLoad, data: getSettingsData} = useGetSettingsQuery(undefined);
    const mpotp_visible = getSettingsData?.result.mpotp_visible;
    const password_visible = getSettingsData?.result.password_visible;

    const userAgent = getUserAgent();

    const [iosContent,setIosContent] = useState(IOSContent)


    const IOS_VIDEO_INSTR = "https://otp.sberbank.ru/instructions/IOS_video_instruction";
    const IOS_EN_INSTR = "https://otp.sberbank.ru/instructions/iOs_instruction_eng.pdf";


    const {width} = useWindowSize();

    const iOSTabHandles = useRef<InstructionTabHandles>(null);
    const androidTabHandles = useRef<InstructionTabHandles>(null);

    const [openModal, setOpenModal] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const [activeIOSMenuStep, setActiveIOSMenuStep] = useState(0)
    const [activeAndroidMenuStep, setActiveAndroidMenuStep] = useState(0)

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleModalActionsClick = (i: number) => () => {
        setActiveTab(i)
        handleCloseModal()
    }

    const handleChangeTab = (event: Object, newValue: number) => {
        setActiveTab(newValue);
    }

    const handleChangeIOSActiveMenuStep = (index: number) => {

        setActiveIOSMenuStep(index)
    }

    const handleClickIOSMenuItem = (i: number) => {
        if (width > 850) {
            iOSTabHandles?.current?.changeActiveItem(IOSContent[i]?.children?.length && !IOSContent[i].img ? 0 : undefined);
        } else {
            iOSTabHandles?.current?.changeActiveItem(0);
        }
        handleChangeIOSActiveMenuStep(i)
    }
    const handleChangeAndroidActiveMenuStep = (index: number) => {
        setActiveAndroidMenuStep(index)
    }

    const handleClickAndroidMenuItem = (i: number) => {
        if (width > 850) {
            androidTabHandles?.current?.changeActiveItem(AndroidContent[i]?.children?.length && !AndroidContent[i].img ? 0 : undefined);
        } else {
            androidTabHandles?.current?.changeActiveItem(0);
        }
        handleChangeAndroidActiveMenuStep(i)
    }

    const getLinkToInstructionPdf = (): string => {
        if (activeTab === 0) {
            if (mpotp_visible){
                return "https://otp.sberbank.ru/instructions/IOS_otp.pdf"
            }
            if (password_visible){
                if (userAgent !== "web"){
                    return "https://otp.sberbank.ru/instructions/IOS_passwords_mobile.pdf"
                }
                return "https://otp.sberbank.ru/instructions/IOS_passwords.pdf"
            }
            return ""
        }
        return "https://otp.sberbank.ru/instructions/Android_instruction.pdf"
    }

    useEffect(() => {
        const iosInstrMap = {
            password:{
                flag : getSettingsData?.result.password_visible,
                content:PasswordIOSContent
            },
            mpotp:{
                flag : getSettingsData?.result.mpotp_visible,
                content: IOSContent
            },
            cyberpass:{
                flag : getSettingsData?.result.cyberpass_instruction_visible,
                content: CyberPassIOSContent
            },
        }
        const iosContent = Object.values(iosInstrMap)?.find(el=>el?.flag)?.content
        setIosContent(iosContent || IOSContent)
    }, [getSettingsData])

    return (
        <div className={style.wrap}>
            <Typography variant={width > 600 ? "h2" : "h5"} className={style.title}>
                Как установить приложение
            </Typography>
            <div className={style.tabsWrap}>
                <Tabs
                    value={activeTab}
                    onChange={handleChangeTab}
                >
                    <Tab
                        label={"iOS"}
                    />
                    <Tab
                        label={"Android"}
                    />
                </Tabs>
                <div className={style.instrWrap}>
                    {getLinkToInstructionPdf() && <a href={getLinkToInstructionPdf()} rel="noreferrer" target={"_blank"}
                        className={style.instructionLink}>Инструкция в PDF <ExternalLinkIcon fontSize={"small"}/>
                    </a>}
                    {(activeTab === 0 && password_visible) &&  <a href={IOS_EN_INSTR} rel="noreferrer" target={"_blank"}
                                            className={style.instructionLink}>PDF instructions<ExternalLinkIcon fontSize={"small"}/>
                    </a>}
                    {(activeTab === 0 && password_visible) && <Tooltip placement="bottom"
                                                 title={<Typography variant={"body2"} style={{padding:8}} color={"error"}>Требуется установка сертификата PKING</Typography>}
                                                 arrow>
                        <a
                            href={IOS_VIDEO_INSTR} rel="noreferrer" target={"_blank"}
                            className={style.instructionLink}>Видеоинструкция IOS&nbsp;
                            <span className={"error-color"}>*</span>
                        </a>
                    </Tooltip>}
                </div>

            </div>
            {
                getSettingsLoad ? <div style={{margin: "32px 0", display: "flex", justifyContent: "center"}}>
                        <CircularLoader size={"large"}/>
                    </div>
                    : <>
                        <div className={style.content}>
                            {
                                activeTab === 0 ? <>
                                        <SideBar listItems={iosContent.map(el => el.menuLabel)}
                                                 activeMenuStep={activeIOSMenuStep}
                                                 onClickMenuItem={handleClickIOSMenuItem}/>
                                        <div className={style.tabContent}>
                                            <InstructionTab activeMenuStep={activeIOSMenuStep}
                                                            handleChangeActiveMenuStep={handleChangeIOSActiveMenuStep}
                                                            ref={iOSTabHandles}
                                                            content={iosContent}
                                                            tab={"ios"}
                                            />
                                        </div>
                                    </>
                                    : <>
                                        <SideBar listItems={AndroidContent.map(el => el.menuLabel)}
                                                 activeMenuStep={activeAndroidMenuStep}
                                                 onClickMenuItem={handleClickAndroidMenuItem}/>
                                        <div className={style.tabContent}>
                                            <InstructionTab activeMenuStep={activeAndroidMenuStep}
                                                            handleChangeActiveMenuStep={handleChangeAndroidActiveMenuStep}
                                                            ref={androidTabHandles}
                                                            content={AndroidContent}
                                                            tab={"android"}
                                            />
                                        </div>
                                    </>
                            }

                        </div>
                        <Dialog open={openModal}>
                            <div className={style.modalContent}>
                                <Typography variant={"h6"}>Выберите мобильное устройство</Typography>
                                <div className={style.modalActions}>
                                    <Button variant="contained" startIcon={<IosIcon/>} onClick={handleModalActionsClick(0)}>
                                        iOS
                                    </Button>
                                    <Button variant="contained" startIcon={<AndroidIcon/>}
                                            onClick={handleModalActionsClick(1)}>
                                        Android
                                    </Button>
                                </div>
                            </div>
                        </Dialog>
                    </>
            }
        </div>
    );
};

export default InstructionsPage;
