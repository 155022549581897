import {useState, useCallback} from "react";
import {
    Box,
    Button,
    Alert,
    Radio,
    CircularLoader,
    Typography,
    NewLink, Checkbox
} from "@sber-friend/flamingo-core";
import {
    devicesApi,
    useGetUserRoleQuery,
    useLazyGetAddIosOmegaCodeQuery,
    useLazyGetAddIosSigmaQrLinkQuery,
    // useGetAddIosSigmaQrLinkMutation
} from "api/devices";
import {useAppDispatch} from "hooks/redux";
import QRCode from "react-qr-code";
import {getUserAgent} from "helpers";
import MyDevicesCoundown from "./MyDevicesCountdown";

type TSegment = "Sigma" | "Omega";

const INSTRUCTION_URL = "https://otp.sberbank.ru/instructions/IOS_qr_instruction.pdf";

const userAgent = getUserAgent();

const MyDevicesIosAdd = () => {
    const dispatch = useAppDispatch();

    const isMobile = userAgent !== "web";

    const [segment, setSegment] = useState<TSegment>("Sigma");
    const [checked, setChecked] = useState(false)

    const {data: userRole} = useGetUserRoleQuery(undefined);
    const multiFactorEnabled = userRole?.result.ui_settings.multifactorEnabled;

    const [getAddIosSigmaQrLink, {
        data: sigmaLinkData,
        isLoading: isSigmaLoading,
        isError: isSigmaError,
        error: sigmaError
    }] = useLazyGetAddIosSigmaQrLinkQuery();

    const [getAddIosOmegaCode, {
        data: omegaCodeData,
        isLoading: isOmegaLoading,
        isError: isOmegaError,
        error: omegaError,
    }] = useLazyGetAddIosOmegaCodeQuery();

    const isLoading = isOmegaLoading || isSigmaLoading;

    const sigmaLink = sigmaLinkData?.result?.otpauth;
    const qrTimer = sigmaLinkData?.result?.qr_timer;

    const omegaCode = omegaCodeData?.result?.safety_code;

    const handleClearQr = useCallback(() => {
        dispatch(devicesApi.util.updateQueryData("getAddIosSigmaQrLink", undefined, (data) => {
            if ("result" in data) {
                data.result.otpauth = "";
            }
        }));
    }, [dispatch])

    const handleSegmentSelect = (segment: TSegment) => {
        setSegment(segment);
    };

    const qrBlock = (
        <Box>
            <Box display="flex" pb={4}>
                <Box pr={2} pl={.5}>
                    <QRCode
                        size={128}
                        style={{
                            height: "auto",
                            width: "128px",
                            marginRight: 10
                        }}
                        value={sigmaLink || ""}
                        viewBox={`0 0 256 256`}
                    />
                </Box>
                <Box mt={-.5} display="flex" flexDirection="column" justifyContent="space-between">
                    <Typography>
                        Отсканируйте QR и в открывшемся приложении <span
                        style={{fontWeight: 600}}>Пароли</span> нажмите <span style={{fontWeight: 600}}>«+»</span>. В
                        карточке укажите любые данные и нажмите <span style={{fontWeight: 600}}>«Сохранить»</span>
                    </Typography>
                    {
                        qrTimer
                            ? <MyDevicesCoundown secondsRemain={qrTimer} onTimerOut={handleClearQr}/>
                            : null
                    }
                </Box>
            </Box>

            <Typography>
                При использовании приложения Пароли устройство не добавится в список устройств.
            </Typography>

        </Box>
    );

    const desktopSigmaBlock = (
        <>
            <Box pb={4}>
                <Alert severity="warning">
                    {
                        sigmaLink
                            ? "QR-код только для сотрудников банка. Не сканируйте его на чужих устройствах. Перед сканированием убедитесь, что рядом никого нет и никто не видит экран."
                            : "На следующем шаге мы покажем QR-код настройки OTP. Этот код только для сотрудников банка. Не сканируйте его на чужих устройствах. Перед сканированием убедитесь, что рядом никого нет и никто не видит экран. У вас будет ограниченное время."
                    }
                </Alert>
            </Box>
            {
                isLoading
                    ? <CircularLoader size="large"/>
                    : sigmaLink
                        ? qrBlock
                        : (
                            <div className={"iosAddCheckbox"}>
                                <Checkbox
                                    label={<>С требованиями ознакомлен. Подтверждаю, что указанные требования выполнены. <span className={"MuiFormLabel-asterisk"}>*</span></>}
                                    checked={checked}
                                    onChange={(e,check)=>setChecked(check)}
                                />
                                <Button
                                    disabled={!checked}
                                    color="primary"
                                    onClick={() => getAddIosSigmaQrLink()}
                                >
                                    {isSigmaError ? "Повторить запрос" : "Показать QR-код"}
                                </Button>
                            </div>

                        )
            }
        </>
    );

    const mobileSigmaBlock = (
        <>
            <Box pb={4}>
                <Typography>
                    Перейдите в приложение <span style={{fontWeight: 600}}>«Пароли»</span> и нажмите <span
                    style={{fontWeight: 600}}>«+»</span>. В карточке укажите любые данные и нажмите <span
                    style={{fontWeight: 600}}> «Сохранить»</span>
                </Typography>
            </Box>
            {
                isLoading
                    ? <CircularLoader size="large"/>
                    : (
                        <Button
                            fullWidth
                            color="primary"
                            onClick={
                                async () => getAddIosSigmaQrLink().then((res) => {
                                    if (res && "data" in res) {
                                        const link = res.data?.result?.otpauth || "";
                                        const a = document.createElement('a');
                                        a.href = link;
                                        a.click();
                                        a.remove();
                                    }
                                })
                            }
                        >
                            Перейти в Пароли
                        </Button>
                    )
            }
        </>
    );

    const sigmaBlock = (
        <>
            {isMobile ? mobileSigmaBlock : desktopSigmaBlock}
            {
                isSigmaError
                    ? (
                        <Alert severity="error" my={3}>
                            {sigmaError?.message}
                        </Alert>
                    ) : null
            }
        </>
    );

    const omegaBlock = multiFactorEnabled ? (
        <>
            <Box pb={2}>
                <Typography>
                    Для регистрации устройства используйте код доступа на портале <span
                    style={{fontWeight: 600}}> otp.omega.sbrf.ru</span> в сегменте Omega.
                </Typography>
            </Box>
            {
                isLoading
                    ? <CircularLoader size="large"/>
                    : isOmegaError
                        ? (
                            <Button
                                color="primary"
                                onClick={async () => getAddIosOmegaCode()}>
                                Повторить запрос
                            </Button>
                        ) : (
                            <Box display="flex" alignItems="flex-end">
                                <Typography style={{marginRight: "8px"}}>
                                    Код доступа:
                                </Typography>
                                <Typography variant="h3">{omegaCode}</Typography>
                            </Box>
                        )

            }
            {
                isOmegaError
                    ? (
                        <Alert severity="error" my={3}>
                            {omegaError?.message}
                        </Alert>
                    ) : null
            }
        </>
    ) : (
        <Box pb={2}>
            <Typography>
                Для получения кода пройдите на портал <span style={{fontWeight: 600}}> otp.omega.sbrf.ru</span> в
                сегменте Omega.
            </Typography>
        </Box>
    );

    return (
        <>
            <Box display="flex" pb={1}>
                <Box ml={-1}>
                    <Radio
                        margin="none"
                        label={"Sigma"}
                        checked={segment === "Sigma"}
                        onChange={() => handleSegmentSelect("Sigma")}
                    />
                </Box>
                <Box ml={4}>
                    <Radio
                        margin="none"
                        label={"Omega"}
                        checked={segment === "Omega"}
                        onChange={() => {
                            handleSegmentSelect("Omega")

                            if (multiFactorEnabled) {
                                getAddIosOmegaCode();
                            }
                        }}
                    />
                </Box>
            </Box>
            <Box pb={2}>
                <NewLink
                    href={INSTRUCTION_URL}
                    target="_blank"
                    style={{fontWeight: 600}}
                >Смотрите инструкцию</NewLink>
            </Box>
            {
                segment === "Sigma" ? sigmaBlock : omegaBlock
            }
        </>
    );
}

export default MyDevicesIosAdd;
