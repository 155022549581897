import { FunctionComponent, ReactNode, useState } from "react";
import MyDevices from "./MyDevices/MyDevices";
import {Tab, Tabs, Typography} from "@sber-friend/flamingo-core";
import style from "./DevicesPage.module.scss";
import Delegation from "./Delegation/Delegation";
import { useGetUserRoleQuery} from "../../api/devices";
import {useEffectOnce, useLocalStorage} from 'usehooks-ts';
import HardwareToken from './HardwareToken/HardwareToken';
import TokenProgramming from './TokenProgramming/TokenProgramming';
import OTP from './OTP/OTP';
import {useSearchParams} from "react-router-dom";
import {setProfile} from "@sbol/clickstream-agent";
import {useAppSelector} from "../../hooks/redux";
import SomethingWrongPage from "./SomethingWrongPage/SomethingWrongPage";
import UnbindingToken from "./UnbindingToken/UnbindingToken";


interface OwnProps {
}

type Props = OwnProps;

// type TabValue = "devices" | "hardware" | "programming" | "unbinding" | "delegation" | "otp";

interface TabData {
    component: ReactNode;
    label: string;
    value: number;
}

interface SectionsAccess {
    delegation: boolean;
    hardwareToken: boolean;
    tokenProgramming: boolean;
    otp: boolean;
}

function getTabsData(sectionsAccess: SectionsAccess): TabData[] {
    const {
        delegation,
        hardwareToken,
        tokenProgramming,
        otp
    } = sectionsAccess;

    const tabsDataList: TabData[] = [
        {
            component: <MyDevices/>,
            label: "Мобильные устройства",
            value: 1
            // value: "devices"
        }
    ];

    if (hardwareToken) {
        tabsDataList.push({
            component: <HardwareToken/>,
            label: "Токен",
            value: 2
            // value: "hardware"
        });
    }

    if (tokenProgramming) {
        tabsDataList.push({
            component: <TokenProgramming/>,
            label: "Программирование",
            value: 3
            // value: "programming"
        });

        tabsDataList.push({
            component: <UnbindingToken/>,
            label: "Отвязка",
            value: 4
            // value: "unbinding"
        });
    }

    if (delegation) {
        tabsDataList.push({
            component: <Delegation/>,
            label: "Делегирование",
            value: 5
            // value: "delegation"
        });
    }

    if (otp) {
        tabsDataList.unshift({
            component: <OTP />,
            label: "OTP",
            value: 0
            // value: "otp"
        });
    }

    return tabsDataList;
};


export const DevicesPage: FunctionComponent<Props> = (props) => {
    
    const somethingWrongError = useAppSelector(state => state.devices.somethingWrongError);

    const {data: userRole} = useGetUserRoleQuery(undefined);

    const [searchParams] = useSearchParams();

    const [isEsiaTokenOmegaReferrer] = useLocalStorage('esiaTokenOmegaReferrer', false);
    const [isSberIdTokenOmegaReferrer] = useLocalStorage('sberIdTokenOmegaReferrer', false);

    const [isEsiaTokenReferrer] = useLocalStorage('esiaTokenSigmaReferrer', false);
    const [isSberIdTokenReferrer] = useLocalStorage('sberIdTokenSigmaReferrer', false);

    const tokenReferrer = (isEsiaTokenOmegaReferrer || isSberIdTokenOmegaReferrer || isSberIdTokenReferrer || isEsiaTokenReferrer)

    const [isEsiaDevicesOmegaReferrer] = useLocalStorage('esiaDevicesOmegaReferrer', false);
    const [isSberIdDevicesOmegaReferrer] = useLocalStorage('sberIdDevicesOmegaReferrer', false);

    const [isEsiaDevicesSigmaReferrer] = useLocalStorage('esiaDevicesSigmaReferrer', false);
    const [isSberIdDevicesSigmaReferrer] = useLocalStorage('sberIdDevicesSigmaReferrer', false);

    const mobileReferrer = (isEsiaDevicesOmegaReferrer || isSberIdDevicesOmegaReferrer || isEsiaDevicesSigmaReferrer || isSberIdDevicesSigmaReferrer);

    const delegation = Boolean(userRole?.result.available_sections.find(e => e === "vip_support"));
    const hardwareToken = Boolean(userRole?.result.available_sections.find(e => e === "hwotp_user"));
    const tokenProgramming = Boolean(userRole?.result.available_sections.find(e => e === "hwotp_admin"));
    const otp = Boolean(userRole?.result.available_sections.find(e => e === "web_otp"));

    const tabsDataList = getTabsData({ 
        delegation, 
        hardwareToken, 
        tokenProgramming, 
        otp
    });

    const [activeTab, setTab] = useState(tabsDataList[0].value);

    const handleChangeTab = (_: Object, newValue: number) => {
        const tabWrap = document.querySelector<HTMLElement>(`.${style.tabContent}`)

        if (newValue < activeTab) {
            tabWrap?.classList.remove(style.lft)
            tabWrap?.classList.remove(style.rtl)
            setTimeout(() => {
                tabWrap?.classList.add(style.lft)
            })
        } else if (newValue > activeTab) {
            tabWrap?.classList.remove(style.rtl)
            tabWrap?.classList.remove(style.lft)
            setTimeout(() => {
                tabWrap?.classList.add(style.rtl)
            })

        }
        setTab(newValue)
    }


    const onMount = async () => {
        if (tokenReferrer && hardwareToken && searchParams.toString()) {
            setTab(2);
        } else if (mobileReferrer && searchParams.toString()) {
            setTab(1);
        }
        // click stream
        const sigmaLogin = userRole?.result.user_login.split('@')[0]
        if (sigmaLogin) {
            setProfile({
                hashUserLoginId: sigmaLogin,
                appVersion: process.env.APP_VERSION,
            })
        }
    }

    useEffectOnce(() => {
        onMount()
    })

    const isMultipleTabs = delegation || hardwareToken || tokenProgramming || otp;

    return somethingWrongError ? <SomethingWrongPage error={somethingWrongError}/>
        : <>
            <div className={style.head}>
                {
                    isMultipleTabs ? (<Tabs
                        value={activeTab}
                        onChange={handleChangeTab}
                    >
                        {tabsDataList.map((tab, index) => (
                            <Tab label={tab.label} value={tab.value} key={index}/>   
                        ))}
                    </Tabs>) : <Typography variant={"h2"}>Устройства</Typography>
                }
            </div> 

            {
                isMultipleTabs ? (
                        <div className={style.tabContent}>
                            {tabsDataList.find(({ value }) => value === activeTab)?.component}
                        </div>)
                    : <MyDevices/>
            }

        </>
};


